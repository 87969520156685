
import React, { useEffect, useReducer } from 'react'
import { Icon } from '../../Theme'
import styles from './index.module.css'
import MediaItem from "./MediaItem";


const ExperienceMedia = ({
    experience,
    onChange,
}) => {
    const [newMedia, setNewMedia] = useReducer(
        (state, value) => ({...state, ...value}),
        {}
    )

    const onMediaInput = (value, index) => {
        const medias = [...experience.medias]

        medias[index].standard_resolution = {...medias[index].standard_resolution, ...value}
        onChange({medias: medias})
    }
    const onMediaRemove = (index) => {
        const medias = [...experience.medias]

        medias.splice(index, 1)
        onChange({medias: medias})
    }

    useEffect(() => {
        if(newMedia.url){
            const medias = [...experience.medias]

            medias.push({standard_resolution: newMedia});
            setNewMedia({name: '', url: ''})
            onChange({medias: medias})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newMedia])

    return (
        <>
        <table border-collapse="true" className={styles.table}>
        <thead>
            <tr>
                <th>Image</th>
                <th>Description</th>
                <th>Action</th>
            </tr>
        </thead>
         <tbody>
            {experience.medias.map((media, i) => {
                return (
                    <MediaItem 
                        key={media.standard_resolution.id || i} 
                        name={media.standard_resolution.name} 
                        url={media.standard_resolution.url}
                        setName={(value) => onMediaInput({name: value}, i)}
                        setUrl={(value => onMediaInput({url: value}, i))}
                        action={<Icon icon="delete" size={2} />}
                        onAction={() => onMediaRemove(i)}
                    />
                )
            })}
            <MediaItem 
                key="new"
                name={newMedia.name} 
                url={newMedia.url}
                setName={(value) => setNewMedia({name: value})}
                setUrl={(value => setNewMedia({url: value}))}
                    />
         </tbody>
      </table>
        </>
    )
}

export default ExperienceMedia