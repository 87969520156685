import React, { useReducer, useState, useRef } from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useForceUpdate } from '../../hooks'
import { Button, Icon, Input, Switch } from '../Theme'
import Page from '../Page'
import OrganizationsList from '../OrganizationsList'
import styles from './index.module.css'
import OrganizationModal from '../OrganizationModal'
import { message } from 'antd'
import LoadingContainer from '../LoadingContainer'

const GET_ORGANIZATIONS_COUNT = gql`
    query getOrganizationsCount (
        $orgname: String,
    ) {
        organizationsCount (
            orgname: $orgname,
        )
    }
`
const DELETE_ORGANIZATION = gql`
    mutation deleteOrganization (
        $id: ID!,
    ) {
        deleteOrganization (
            id: $id,
        ) {
            success
        }
    }
`

const Organizations = () => {
    const [organizationModal, toggleOrganizationModal] = useState(false)
    const [selectedOrganization, setSelectedOrganization] = useState(undefined)
    const [selectedCount, setSelectedCount] = useState(0)
    const selected = useRef(new Set())
    const [reloaded, reload] = useForceUpdate()
    const [deleteOrganization, { loading: deleting }] = useMutation(DELETE_ORGANIZATION)

    const [filters, setFilters] = useReducer(
        (state, [field, value]) => ({...state, [field]: value}),
        {
            name: '',
            sortBy: 'updated_date',
        }
    )

    const {
        data: organizationsCountResponse,
        refetch: recountOrganizations,
        loading
    } = useQuery(GET_ORGANIZATIONS_COUNT)

    const filter = (key, value) => {
        setFilters([key, value])
        recountOrganizations({...filters, [key]: value})
            .catch(console.error)
    }

    const onSelect = (id) => {
        if (selected.current.has(id)) {
            selected.current.delete(id)
        } else {
            selected.current.add(id)
        }

        setSelectedCount(
            [...selected.current].length
        )
    }

    const onDelete = () => {
        Promise.all(
            [...selected.current].map((id) => {
                    return deleteOrganization({ variables: { id } })
            })
        ).then(
            () => {
                message.success("Partners successfully deleted")
                selected.current = new Set()
                setSelectedCount(0)
                reload()
            }
        ).catch(console.error)
    }

    return (
        <Page
            title="Partners"
            icon="datasets"
            help={{
                title: 'How Partners Work',
                description: 'This section allows you to Create, Update or Delete your Organization and obtain API test and dev keys for integration.',
                sections: [
                    [
                        'Organize:',
                        <p>Select the Partner(s) you’d like to delete by clicking on the checkbox.</p>
                    ],
                    [
                        'Implement:',
                        <p>Once selected, Delete Partner(s) via the button below.</p>
                    ]
                ]
            }}
            buttons={[
                <Button
                    key="create-new-organization"
                    onClick={() => toggleOrganizationModal(true)}
                    primary>
                        <Icon 
                            icon="add"
                            size={2}
                        />
                        Create New Partner
                </Button>,
                <Button
                    key="delete-datasets"
                    color="red"
                    disabled={selectedCount === 0 || undefined}
                    loading={deleting}
                    onClick={onDelete}
                    primary>
                        <Icon 
                            icon="delete"
                            size={2}
                        />
                        Delete {selectedCount ? selectedCount : ''} Partners
                </Button>
            ]}>
                <div>
                    <Input
                        placeholder="Search by name"
                        icon="search"
                        onValueChange={value => filter('orgname', value)}
                    />

                    <div>
                        <span className="mr-2">Sort By</span>
                        <Switch
                            value={filters.sortBy}
                            onChange={value => filter('sortBy', value)}
                            values={[
                                ['Modified', 'updated_date'],
                                ['Name', 'orgname'],
                            ]}
                        />
                    </div>
                </div>

                <div className={styles.refreshBar}>
                    {organizationsCountResponse && (
                        <h3>{organizationsCountResponse.organizationsCount} Partners</h3>
                    )}
                </div>
                
                <LoadingContainer loading={loading}>
                    {reloaded && (
                        <OrganizationsList
                            filters={filters}
                            onSelect={onSelect}
                            onClick={(orgId) => {
                                    setSelectedOrganization(orgId)
                                    toggleOrganizationModal(true)
                                }
                            }
                        />
                    )}
                </LoadingContainer>


                {organizationModal && (
                    <OrganizationModal
                        organization={selectedOrganization}
                        reload={reload}
                        onClose={() => {
                                setSelectedOrganization(undefined)
                                toggleOrganizationModal(false)
                            }
                        }
                    />
                )}
        </Page>
    )
}

export default Organizations