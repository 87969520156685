import React from 'react'
import { Link } from 'react-router-dom'
import { useUser } from '../../hocs'
import { Card, Icon } from '../Theme'
import Loader from '../Loader'
import styles from './index.module.css'

const SECTIONS = [
    {
        icon: 'datasets',
        title: 'Datasets',
        subtitle: 'Organize your data',
        description: 'Build new datasets and manage your existing one’s here.',
        path: '/city-collections',
    },
    {
        icon: 'experiences',
        title: 'Experiences',
        subtitle: 'Organize your experiences',
        description: 'Curated recommendations for restaurants, fashion, and unique experiences around the world from our Explorers.',
        path: '/experiences',
    },
    {
        icon: 'explorers',
        title: 'Explorers',
        subtitle: 'Organize your explorers',
        description: 'Hundreds of Explorers from around the world who will help you tap into the soul of a destination.',
        path: '/explorers',
    },
    {
        icon: 'cities',
        title: 'Cities',
        subtitle: 'Organize your cities',
        description: 'The top destinations in the world. If you don’t find your city here, let us know and we will add it.',
        path: '/cities',
    },
]

const Home = () => {
    const { user } = useUser()

    if (!user) {
        return <Loader/>
    }

    return (
        <div className={styles.wrapper}>
            <div>
                <Card className={styles.avatarCard}>
                    {user.avatar && (
                        <img 
                            src={user.avatar}
                            alt="avatar"
                        />
                    )}
                    <div>
                        {user.fullName && user.fullName.length && (
                            <p>Welcome {user.fullName}</p>
                        )}
                        <Link to="/account">
                            My Account
                        </Link>
                    </div>
                </Card>

                <div className={styles.sections}>
                    {SECTIONS.map(
                        ({
                            icon,
                            title,
                            subtitle,
                            description,
                            path,
                        }) => (
                            <Card 
                                key={`section-${title}`}
                                className={styles.section}>
                                    <h5>
                                        <Icon 
                                            icon={icon}
                                            size={2}
                                        />
                                        {title}
                                    </h5>
                                    <h6>{subtitle}</h6>
                                    <p>{description}</p>
                                    <Link to={path}>
                                        {title}
                                    </Link>
                            </Card>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

export default Home