import React from 'react'
import { Route } from 'react-router-dom'
import { withAuth, useUser, withRouter, withApollo } from '../../hocs'
import Auth from '../Auth'
import Dashboard from '../Dashboard'
import Home from '../Home'
import Experiences from '../Experiences'
import Explorers from '../Explorers'
import Explorer from '../Explorer'
import CityCollections from '../CityCollections'
import CityCollection from '../CityCollection'
import Guides from '../Guides'
import Guide from '../Guide'
import Cities from '../Cities'
import Videos from '../Videos'
import Video from '../Video'
import Organizations from '../Organizations'
import Account from '../Account'

const ROUTES = [
    { path: '/', component: Home },
    { path: '/explorers/:id', component: Explorer },
    { path: '/city-collections', component: CityCollections },
    { path: '/city-collections/:id', component: CityCollection },
    { path: '/guides/:id', component: Guide },
    { path: '/videos/:id', component: Video },
    { path: '/account', component: Account },
    { path: '/experiences', component: Experiences },
    { path: '/explorers', component: Explorers },
    { path: '/guides', component: Guides },
    { path: '/videos', component: Videos },
    { path: '/cities', component: Cities },
]

const ADMIN_ROUTES = [
    { path: '/organizations', component: Organizations },
    ...ROUTES
]

const App = () => {
    const { user } = useUser()

    if (!user) {
        return <Auth/>
    }

    const routes = user.isAdmin ? ADMIN_ROUTES : ROUTES

    return (
        <Dashboard>
            {routes.map((route) => (
                <Route
                    key={`route-${route.path}`}
                    path={route.path}
                    exact>
                    <route.component/>
                </Route>
            ))}
        </Dashboard>
    )
}

export default withApollo(
    withRouter(
        withAuth(
            App
        )
    )
)