import React, { useMemo } from 'react'
import { Checkbox} from '../../Theme'
import styles from './index.module.css'

const OrganizationPermissionsForm = ({
    list,
    permissions,
    onChange
}) => {
    const optionList = useMemo(() => Object.keys(list), [list])
    const rigthColumn = useMemo(() => optionList.filter((op, i) => i % 2 === 0), [optionList])
    const leftColumn = useMemo(() => optionList.filter((op, i) => i % 2 !== 0), [optionList])

    return (
        <div className={styles.container}>
        
            <h6 className={styles.header}>Permissions</h6>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>

                <div>
                    {rigthColumn.map((option, i) => (
                        <div className={styles.listWrapper}>
                            <Checkbox
                                key={i + 'key'} 
                                name={option}
                                value={permissions[option] || false}
                                onChange={(data) => onChange({
                                    ...permissions,
                                    [option]: data
                                })}>   
                                <span className={styles.name}>
                                    { list[option] }
                                </span>
                            </Checkbox>
                        </div>
                    ))}
                </div>
                <div>
                    {leftColumn.map((option, i) => (
                        <div className={styles.listWrapper}>
                            <Checkbox
                                key={i + 'key'} 
                                name={option}
                                value={permissions[option] || false}
                                onChange={(data) => onChange({
                                    ...permissions,
                                    [option]: data
                                })}>   
                                <span className={styles.name}>
                                    { list[option] }
                                </span>
                            </Checkbox>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default OrganizationPermissionsForm