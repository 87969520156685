import React, { useMemo, useReducer, useState } from 'react'
import { gql } from 'apollo-boost'
import { Modal, Button, Tabs } from '../Theme'
import ExperiencesForm from "./ExperiencesForm"
import styles from './index.module.css'
import { useMutation } from '@apollo/react-hooks'
import { message } from 'antd'
import ExperienceMedia from './ExperienceMedia'

const UPDATE_EXPERIENCE = gql`
    mutation updateActivity (
        $id: ID!,
        $name: String,
        $description: String,
        $category: String,
        $recommendation_tags: String
        $medias: [MediaInput],
    ) {
        updateActivity (
            id: $id,
            name: $name,
            description: $description,
            category: $category,
            recommendation_tags: $recommendation_tags,
            medias: $medias
        ) {
            _id,
            name,
            description,
            recommendation_tags,
            category,
        }
    }
`


const ExperiencesModal = ({
    onClose,
    experience,
}) => {
    const [tab, setTab] = useState('Content')
    const [expData, setExpData] = useReducer(
        (state, value) => ({...state, ...value}),
        experience
    )
    const [updateReq, {loading}] = useMutation(UPDATE_EXPERIENCE);
    const Form = useMemo(() => {
        let Form
        switch (tab) {
            case 'Content':
                Form = ExperiencesForm
                break;
            case 'Media':
                Form = ExperienceMedia
            break;
            default:
                break;
        }

        return (
            <Form experience={expData} onChange={setExpData}/>
        )
    }, [tab, expData])
    const submit = () => {
        const variables = {...expData, id: expData._id}

        variables.medias = expData.medias.filter((media) => {
            delete media.standard_resolution.__typename
            delete media.__typename

            return (media.standard_resolution.url)
        })
        
        updateReq({variables: variables})
            .then(() => {
                message.success('Experience successfully updated}')
                onClose()
            })
            .catch((e) => {
                const error = e.graphQLErrors && e.graphQLErrors[0]
                error ? message.error(error) : message.error(e.message)
            })
    }

    return (
        <Modal
            title="Update Experience"
            showClose={true}
            wrapBody={true}
            onClose={onClose}
            maxWidth={1200}
        >
            <div>
                <div className={styles.tabsContainer}>
                    <Tabs
                        activeTab={tab}
                        tabs={['Content', 'Media']}
                        onChange={setTab}
                    />
                </div>
                <div className={styles.formSection}>
                    {Form}
                </div>
                <div className={styles.footer}>
                    <Button
                        style={{width: 180}}
                        onClick={onClose}>
                            Cancel
                    </Button>
                    <Button 
                        color="blue" 
                        style={{width: 180}}
                        onClick={submit}
                        loading={loading}
                        primary>
                            Save
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ExperiencesModal