import React, { useEffect, useState } from 'react'
import { Modal, Button, Input } from '../../Theme'
import styles from './index.module.css'


const TokensModal = ({
    liveToken,
    testToken,
    onUnpublish,
    onClose
}
) => {
    

    return (
        <Modal
            title="Api Tokens"
            showClose={true}
            wrapBody={true}
            onClose={() => onClose()}
            maxWidth={1200}
        >
            <div>
                <div className={styles.tokens}>
                    <h3>Live access token</h3>
                    <div>   
                        {liveToken}
                    </div>
                </div>
                <div className={styles.tokens}>
                    <h3>Test access token</h3>
                    <div>   
                        {testToken}
                    </div>
                </div>
                <div className={styles.unpublish}>
                    {onUnpublish && <Button
                        color="red" 
                        style={{
                            alignSelf: 'flex-end',
                            width: 180
                        }}
                        onClick={() => onUnpublish() && onClose()}
                        primary>
                            Unpublish
                    </Button>}
                </div>
                <div className={styles.footer}>
                    <Button
                        color="blcak" 
                        style={{width: 180}}
                        onClick={onClose}
                        primary>
                            Cancel
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default TokensModal