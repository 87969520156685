import React from 'react'
import Button from '../Button'
import styles from './index.module.css'

const Tabs = ({
    activeTab,
    onChange = () => {},
    tabs,
}) => {
    return (
        <div className={styles.tabs}>
            {tabs.map(
                tab => (
                    <Button 
                        key={`tab-${tab}`}
                        onClick={() => onChange(tab)}>
                            <span data-active={tab === activeTab}>
                                {tab}
                            </span>
                    </Button>
                )
            )}
        </div>
    )
}

export default Tabs