import React, { useState, useReducer, useEffect } from 'react'
import { message } from 'antd'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import Page from '../Page'
import Loader from '../Loader'
import { Input, Button, Icon } from '../Theme'
import styles from './index.module.css'
import { useUser } from '../../hocs'
import ChangePasswordModal from './ChangePasswordModal'
import ChangeImageModal from './ChangeImageModal'

const UPDATE_USER = gql`
    mutation updateUser (
        $orgname: String,
        $fullName: String,
        $avatar: String,
        $logo: String,
    ) {
        updateUser (
            orgname: $orgname,
            fullName: $fullName,
            avatar: $avatar,
            logo: $logo,
        ) {
            success,
        }
    }
`

const Account = () => {
    const { user, getUser } = useUser()
    const [passwordResetModal, showPasswordResetModal] = useState(false)
    const [changeAvatarModal, showChangeAvatarModal] = useState(false)
    const [changeLogoModal, showChangeLogoModal] = useState(false)
    const [updateUser] = useMutation(UPDATE_USER)
    const [variables, set] = useReducer(
        (state, [field, value]) => ({...state, [field]: value}),
        {
            orgname: '',
            fullName: '',
            avatar: '',
            logo: '',
        }
    )

    const submit = () => updateUser({ variables })
        .then(() => {
            message.success('Successfully updated!')
            getUser()
        })
        .catch(console.error)

    useEffect(() => {
        if (!user) {
            return
        }

        set(['orgname', user.orgname])
        set(['fullName', user.fullName])
        set(['avatar', user.avatar])
        set(['logo', user.logo])
    }, [user])

    if (!user) {
        return <Loader/>
    }

    return (
        <Page
            title="Account"
            icon="account"
        >
            <div className={styles.account}>
                <Input
                    label="Partner Name"
                    value={variables.orgname}
                    onChange={e => set(['orgname', e.target.value])}
                />
                <Input
                    label="Name"
                    value={variables.fullName}
                    onChange={e => set(['fullName', e.target.value])}
                />
                <Input
                    label="E-Mail"
                    value={user.email}
                    disabled
                />
            </div>
            <div className={styles.actions}>
                <div>
                    <h6>Password</h6>
                    <a
                        href="/#"
                        onClick={e => {
                            e.preventDefault()
                            showPasswordResetModal(true)
                        }}>
                            <Icon 
                                icon="lock"
                                size={2}
                            />
                            Change Password
                    </a>
                </div>
                <div>
                    <h6>Avatar</h6>
                    <a 
                        href="/#"
                        onClick={e => {
                            e.preventDefault()
                            showChangeAvatarModal(true)
                        }}>
                            {variables.avatar && (
                                <img 
                                    src={variables.avatar}
                                    alt="avatar"
                                />
                            )}
                            Change Avatar
                    </a>
                </div>
                <div>
                    <h6>Logo</h6>
                    <a
                        href="/#"
                        onClick={e => {
                            e.preventDefault()
                            showChangeLogoModal(true)
                        }}>
                        {variables.logo && (
                            <img 
                                src={variables.logo}
                                alt="user logo"
                            />
                        )}
                        Change Logo
                    </a>
                </div>
            </div>
            <div className={styles.footer}>
                <Button
                    color="green"
                    style={{width: 180}}
                    onClick={submit}
                    primary>
                        Update
                </Button>
            </div>

            {passwordResetModal && (
                <ChangePasswordModal
                    onClose={() => showPasswordResetModal(false)}/>
            )}

            {changeAvatarModal && (
                <ChangeImageModal
                    value={variables.avatar}
                    name="Avatar"
                    setValue={avatar => set(['avatar', avatar])}
                    submit={submit}
                    onClose={() => showChangeAvatarModal(false)}
                />
            )}
            
            {changeLogoModal && (
                <ChangeImageModal
                    value={variables.logo}
                    name="Logo"
                    setValue={logo => set(['logo', logo])}
                    submit={submit}
                    onClose={() => showChangeLogoModal(false)}
                />
            )}
        </Page>
    )
}

export default Account