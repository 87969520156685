export default {
    development: {
        APOLLO_SERVER: `https://partner-dashboard-api.rad.travel`,
        // APOLLO_SERVER: 'http://localhost:8080',
        S3_CONFIG: {
            ACL: 'public-read',
            bucket: 'rad-public',
            apiVersion: '2006-03-01',
            region: 'us-east-1',
            IdentityPoolId: 'us-east-1:7051260e-f680-4cbd-b571-11346bfea1d5'
        }
    },
    staging: {
        APOLLO_SERVER: `https://partner-dashboard-api.rad.travel`,
        S3_CONFIG: {
            ACL: 'public-read',
            bucket: 'rad-public',
            apiVersion: '2006-03-01',
            region: 'us-east-1',
            IdentityPoolId: 'us-east-1:7051260e-f680-4cbd-b571-11346bfea1d5'
        }
    },
    production: {
        APOLLO_SERVER: `https://partner-dashboard-api.rad.travel`,
        S3_CONFIG: {
            ACL: 'public-read',
            bucket: 'rad-public',
            apiVersion: '2006-03-01',
            region: 'us-east-1',
            IdentityPoolId: 'us-east-1:7051260e-f680-4cbd-b571-11346bfea1d5'
        }
    },
}[process.env.REACT_APP_ENV]