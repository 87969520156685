import React, { useReducer, useState } from 'react'
import { message } from 'antd'
import { Link } from 'react-router-dom'
import styles from './index.module.css'
import { useUser } from '../../hocs'
import { Checkbox, Input, Button } from '../Theme'
import Loader from '../Loader'

const Login = () => {
    const { login } = useUser()
    const [loading, setLoading] = useState()
    const [variables, set] = useReducer(
        (state, [field, value]) => ({...state, [field]: value}),
        {
            email: '',
            password: '',
            remember: false,
        }
    )

    const submit = () => {
        setLoading(true)
        login({ variables })
        .catch(e => {
            const error = e.graphQLErrors && e.graphQLErrors[0]
            if (error) message.error(error.message)
        })
        .finally(() => setLoading(false))
    }

    return (
        <div>
            <Input 
                type="email"
                placeholder="E-Mail Address"
                label="E-Mail Address"
                onChange={e => set(['email', e.target.value])}
                onEnter={submit}
            />
            <Input 
                type="password"
                placeholder="Password"
                label="Password"
                onChange={e => set(['password', e.target.value])}
                onEnter={submit}
            />
            <div className={styles.options}>
                <Checkbox
                    value={variables.remember}
                    name="remember"
                    onChange={value => set(['remember', value])}>
                        Keep me signed in
                </Checkbox>
                <Link to="/forgot-password">
                    Forgot Password ?
                </Link>
            </div>
            <div className={styles.buttonWrapper}>
                { !loading 
                ? <Button 
                    onClick={submit}
                    style={{width: 150}}
                    primary>
                    Sign In
                </Button>

                : <div className={styles.loader}>
                    <Loader className={styles.loading} />
                </div>}
            </div>
        </div>
    )
}

export default Login