import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { gql } from 'apollo-boost'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useQuery } from '@apollo/react-hooks'
import { Checkbox } from '../Theme'
import { useEffectSkipFirst } from '../../hooks'
import moment from 'moment'
import Loader from '../Loader'
import styles from './index.module.css'

const GET_CITY_COLLECTIONS = gql`
    query getCityCollections (
        $name: String,
        $sortBy: String,
        $sortDirection: Int,
        $page: Int,
    ) {
        cityCollections (
            name: $name,
            sortBy: $sortBy,
            sortDirection: $sortDirection,
            page: $page,
        ) {
            _id,
            name,
            published_date,
            cities {
                _id,
            },
        }
    }
`

const CityCollectionsList = ({
    clickable = true,
    filters,
    onSelect,
    height,
}) => {
    let history = useHistory()
    const [page, setPage] = useState(1)

    const {
        loading: cityCollectionsLoading,
        data: cityCollectionsResponse,
        fetchMore: fetchMoreCityCollections,
        refetch: loadCityCollections,
    } = useQuery(GET_CITY_COLLECTIONS, {
        variables: filters,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    })

    const loadMoreCityCollections = () => {
        fetchMoreCityCollections({
            variables: {
                page: page + 1
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev
                }

                setPage(
                    page => page + 1
                )

                return Object.assign(
                    {},
                    prev,
                    {
                        cityCollections: [
                            ...prev.cityCollections,
                            ...fetchMoreResult.cityCollections
                        ]
                    }
                );
            }
        })
    }
    
    useEffectSkipFirst(() => {
        loadCityCollections(filters)
            .then(() => console.log('loaded'))
            .catch(console.error)
    }, [filters])

    return (
        <div>
            {cityCollectionsResponse && (
                <ul className={styles.cityCollectionsWrapper}>
                    <InfiniteScroll
                        dataLength={cityCollectionsResponse.cityCollections.length}
                        next={loadMoreCityCollections}
                        height={height}
                        hasMore={true}>
                            {cityCollectionsResponse.cityCollections.map(
                                collection => (
                                    <li 
                                        key={`collection-${collection._id}`}
                                        className={styles.collection}>
                                            <div className="mr-1 ml-1">
                                                <Checkbox
                                                    onChange={(value) => onSelect(collection._id)}/>
                                            </div>
                                            <div className={styles.collectionItem} onClick={() => clickable && history.push(`/city-collections/${collection._id}`)}>    
                                                <h3>{collection.name}</h3>
                                                {/* <p>
                                                    {collection.published_date ? (
                                                        <span>
                                                            Published on {moment(collection.published_date).format('MMM DD')}
                                                        </span>
                                                    ) : (
                                                        <span>Unpublished</span>
                                                    )}
                                                   
                                                </p> */}
                                            </div>
                                    </li>
                                )
                            )}
                    </InfiniteScroll>
                </ul>
            )}

            {cityCollectionsLoading && (
                <Loader/>
            )}
        </div>
    )
}

export default CityCollectionsList