import React, { useEffect } from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const GET_CITY_COLLECTION = gql`
    query getCityCollectionItemCount (
        $slug: String, 
        $model: String,
    ) {
        cityCollectionItemCount (
            slug: $slug,
            model: $model,
        )
    }
`

const getModel = (type) => {
    switch (type) {
        case "Experiences":
            return "Activity"
        case "Cities":
            return "City"
        case "Explorers":
            return "Explorer"
        case "Guides":
            return "Guide"
        case "Videos":
            return "Video"
        default:
            console.error("Model not defined")
    }
}


const CityCollectionCount = ({ slug, type, reload }) => {
    const {
        data: countResponse,
        refetch: refetchCount
    } = useQuery(GET_CITY_COLLECTION, {
        fetchPolicy: "no-cache",
        variables: {
            slug: slug,
            model: getModel(type),
        }
    })

    useEffect(() => {
        refetchCount({ slug, type })
    }, [refetchCount, slug, type, reload])


    const count = countResponse
        ? countResponse.cityCollectionItemCount
        : 0


    return (
        <div>
            {`${count} ${type}`}
        </div>
    )
}

export default CityCollectionCount