import React from 'react'
import { Input} from '../../Theme'
import styles from './index.module.css'

const ExperiencesForm = ({
    experience,
    onChange
}) => {
    return (
       <div className={styles.form}>    
            <Input
                label="Experience Name"
                value={experience.name}
                required="Name required"
                onChange={e => onChange({
                                'name': e.target.value, 
                                })}
            />
            <Input 
                label="Description"
                textarea={true}
                value={experience.description}
                onChange={(e) => onChange({ 'description': e.target.value })}
            />
            <Input 
                label="Category"
                value={experience.category}
                onChange={(e) => onChange({ 'category': e.target.value })}
            />
            <Input 
                label="Recommedation Tags"
                value={experience.recommendation_tags}
                onChange={(e) => onChange({ 'recommendation_tags': e.target.value })}
            />
        </div>
    )
}

export default ExperiencesForm