import React, { useRef, useEffect } from 'react'
import { Icon } from '../../Theme'
import styles from './index.module.css'

const Input = ({
    type,
    placeholder,
    value,
    label,
    icon,
    disabled,
    textarea = false,
    onChange = () => {},
    onValueChange = () => {},
    onEnter = () => {},
}) => {
    const throttling = useRef()

    const onNativeChange = (e) => {
        onChange(e)
        
        if (throttling.current) {
            clearTimeout(throttling.current)
            throttling.current = null
        }

        const value = e.target.value
        throttling.current = setTimeout(() => {
            onValueChange(value)
        }, 300)
    }

    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            onEnter()
        }
    }

    useEffect(() => {
        onChange({ target: { value } })
    }, [])

    return (
        <div className={styles.formControl}>
            {label && (
                <label>
                    {label}
                </label>
            )}
            <div 
                className={styles.inputWrapper}
                data-icon={icon}>
                    {!textarea ? (<input
                        type={type}
                        onChange={onNativeChange}
                        placeholder={placeholder}
                        value={value}
                        onKeyUp={onKeyUp}
                        disabled={disabled}
                    />) : (
                        <textarea
                        onChange={onNativeChange}
                        placeholder={placeholder}
                        value={value}
                        onKeyUp={onKeyUp}
                        disabled={disabled}
                    />
                    )}
                    {icon && (
                        <Icon
                            icon={icon}
                            size={2.5}
                        />
                    )}
            </div>
        </div>
    )
}

export default Input