import React from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { useParams, Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import { Icon } from '../Theme'
import Loader from '../Loader'
import BreadCumps from '../BreadCrumps'
import Page from '../Page'
import { CLOUDINARY_URL } from '../../constants'
import styles from './index.module.css'

const GET_VIDEO = gql`
    query getVideo (
        $id: ID,
    ) {
        video (
            id: $id,
        ) {
            _id,
            name,
            description,
            thumbnail,
            url,
            created_date,
        }
    }
`

const Video = () => {
    const { id } = useParams()
    const { data: videoResponse, loading: loadingVideo } = useQuery(GET_VIDEO, {
        variables: {
            id
        }
    })

    if (loadingVideo) {
        return <Loader />
    }

    const { video } = videoResponse
    console.log("video", video);


    return (
        <Page
            customTitle={<>
                <Icon
                    icon="datasets"
                    size={2.5} />
                <h1 className={styles.title}>
                    <BreadCumps breadcumps={['Videos']} activeName={video.name}/>
                </h1>
            </>}>
            <div className={styles.video}>
                <div className={styles.videoCover}>
                    {video.thumbnail && (
                        <img
                            src={CLOUDINARY_URL + '/w_250,f_auto/' + video.thumbnail}
                            alt={video.name + 'cover'}
                        />
                    )}
                </div>
                <div className={styles.videoBody}>
                    <h3>{video.name}</h3>
                    <p>
                        Published on
                            <span className="mr-1 ml-1">
                            {moment(video.created_date).format('MMM DD')}
                        </span>
                    </p>
                    <p>{video.description}</p>
                </div>
            </div>
            <div className={styles.videoPreview}>
                <iframe
                    src={`https://player.vimeo.com/video/${video.url.split("/")[2]}`}
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                    title="THROUGH THE EYES: SINGAPORE"
                    />
            </div>
        </Page >
    )
}

export default Video