import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Checkbox } from '../Theme'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { useEffectSkipFirst } from '../../hooks'
import { CLOUDINARY_URL, RADMIN_URL } from '../../constants'
import Loader from '../Loader'
import { useUser } from '../../hocs/auth'
import styles from './index.module.css'
import EmptyResult from '../EmptyResult'

const GET_EXPLORERS = gql`
    query getExplorers (
        $name: String,
        $full_name: String,
        $sortBy: String,
        $country: String,
        $city: String,
        $page: Int,
        $dataset: String,
    ) {
        explorers (
            name: $name,
            full_name: $full_name,
            sortBy: $sortBy,
            country: $country,
            city: $city,
            page: $page,
            dataset: $dataset,
        ) {
            _id,
            email,
            full_name,
            profile_picture,
            activities_count,
            email,
        }
    }
`

const ExplorersList = ({
    actions= true,
    filters,
    onSelect,
    scrollableTarget,
    onClickExplorer,
}) => {
    let history = useHistory()
    const { user } = useUser()
    const [page, setPage] = useState(1)
    const [moreLoading, setLoading] = useState()

    const {
        data: explorersResponse,
        fetchMore: fetchMoreExplorers,
        refetch: loadExplorers
    } = useQuery(GET_EXPLORERS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        variables: filters,
    })

    const loadMoreExplorers = () => {
        setLoading(true)
        fetchMoreExplorers({
            variables: {
                page: page + 1
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev
                }

                setPage(
                    page => page + 1
                )

                return Object.assign(
                    {},
                    prev,
                    {
                        explorers: [
                            ...prev.explorers,
                            ...fetchMoreResult.explorers
                        ]
                    }
                );
            }
        }).then(_ => 
        setLoading(false))
    }

    const onImageError = (e) => {
        e.target.src = "https://res.cloudinary.com/dbur3sjwh/image/upload/v1590513469/placeholder-profile_omjhxm.jpg"
    }

  
    useEffectSkipFirst(() => {
        loadExplorers(filters)
            .catch(console.error)
    }, [filters])

    return (
        <div>
            {explorersResponse && (
                <ul className={styles.explorersWrapper}>
                    <InfiniteScroll
                        scrollableTarget={scrollableTarget || undefined}
                        dataLength={explorersResponse.explorers.length}
                        next={loadMoreExplorers}
                        hasMore={true}>
                            {explorersResponse.explorers.length > 0 ? explorersResponse.explorers.map(
                                explorer => (
                                    <li 
                                        key={`explorer-${explorer._id}`}
                                        className={styles.explorer}>
                                            {onSelect && (
                                                <div
                                                     className="ml-1 mr-1">
                                                    <Checkbox
                                                        onChange={(value) => onSelect(explorer._id)}
                                                    />
                                                </div>
                                            )}
                                        <div
                                            className={styles.explorerItem}
                                            // onClick={onClickExplorer 
                                                // ? () => onClickExplorer(explorer._id)
                                                // : () => history.push(`/explorers/${explorer._id}`)}
                                            onClick={onClickExplorer 
                                                ? () => onClickExplorer(explorer._id)
                                                : () => explorer.email 
                                                    ? window.open(`${RADMIN_URL}/e/${explorer.email}`)
                                                    : window.open(`${RADMIN_URL}/e/${explorer._id}`)}
                                            >
                                            <div className={styles.explorerImage}>
                                                <img
                                                    src={CLOUDINARY_URL + '/w_100,f_auto/' + explorer.profile_picture}
                                                    onError={onImageError}
                                                    alt={explorer.name + ' profile'}
                                                />
                                            </div>
                                            <div className={styles.explorerBody}>
                                                <h3>{explorer.full_name}</h3>
                                                <p>
                                                    {explorer.activities_count}
                                                    <span className="ml-1">
                                                        {explorer.activities_count > 1 ? 'Experiences' : 'Experience'}
                                                    </span>
                                                </p>
                                            </div>
                                            {actions && <div className={styles.editButton}>
                                                {(user.isAdmin || user?.permissions?.editExplorer) && <a
                                                    style={{marginRight: '10px'}}
                                                    target="_blank"
                                                    href={`${RADMIN_URL}/e/${explorer._id}?org_token=${localStorage.getItem('token')}&redirect_url=${window.location.href}&editable=true`}
                                                    onClick={(e) => e.stopPropagation()}>
                                                    Edit
                                                </a>}
                                               {/* <a
                                                    target="_blank"
                                                    href={`${RADMIN_URL}/e/${explorer.email}`}
                                                    onClick={(e) => e.stopPropagation()}>
                                                    Preview
                                                </a> */}
                                            </div>}
                                        </div>
                                    </li>
                                )
                            ) : <EmptyResult/>}
                    </InfiniteScroll>
                </ul>
            )}

            {moreLoading && (
                <Loader/>
            )}
        </div>
    )
}

export default ExplorersList