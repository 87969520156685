import React from 'react'
import styles from './index.module.css'
import TextField from '../PedTextField'


const Step3Form = ({
    pedSettings,
    onChange,
}) => {
    return (
        <>
        <div className={styles.form}>    
            <table border-collapse="true" className={styles.table}>
                <thead className={styles.textFields}>
                    <tr>
                        <th>Field</th>
                        <th>Content</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <TextField
                        label="Quetion"
                        onChange={value => {
                            onChange({'question3': value})}
                        }
                        value={pedSettings.question3}
                    />
                </tbody>
            </table>
        </div>
        </>
    )
}

export default Step3Form