import React, { useState } from 'react'
import { message } from 'antd'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { Input, Button } from '../Theme'
import styles from './index.module.css'

const FORGOT_PASSWORD = gql`
    mutation forgotPassword (
        $email: String!,
    ) {
        forgotPassword (
            email: $email,
        ) {
            success,
        }
    }
`

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [forgotPassword] = useMutation(FORGOT_PASSWORD)

    const submit = () => forgotPassword({
        variables: { email }
    }).then(() => {
        message.success('Reset instructions successfully sent to provided email address.')
    }).catch(e => {
        const error = e.graphQLErrors && e.graphQLErrors[0]
        if (error) message.error(error.message)
    })

    return (
        <div>
            <div className={styles.backToLogin}>
                <Link to="/">
                    Back To Login
                </Link>
            </div>
            <Input 
                type="email"
                placeholder="E-Mail Address"
                label="* E-Mail Address"
                onChange={e => setEmail(e.target.value)}
                onEnter={submit}
            />
            <div className={styles.buttonWrapper}>
                <Button 
                    onClick={submit}
                    style={{width: 150}}
                    primary>
                        Continue
                </Button>
            </div>
        </div>
    )
}

export default ForgotPassword