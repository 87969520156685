import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../Footer'
import styles from './index.module.css'
import { useUser } from '../../hocs'
import { Icon } from '../Theme'

const NAVIGATION = [
    // { path: '/', name: 'Home', icon: 'home' },
    { path: '/experiences', name: 'Experiences', icon: 'experiences' },
    { path: '/explorers', name: 'Explorers', icon: 'explorers' },
    { path: '/guides', name: 'Guides', icon: 'map' },
    { path: '/cities', name: 'Cities', icon: 'location' },
    // { path: '/videos', name: 'Videos', icon: 'datasets' },
    { path: '/city-collections', name: 'Datasets', icon: 'datasets' },
]

const ADMIN_NAVIGATION = [
    ...NAVIGATION,
    { path: '/organizations', name: 'Partners', icon: 'yelp' },
]

const Dashboard = ({
    children,
}) => {
    const { logout, user } = useUser()

    const navigation = user.isAdmin ? ADMIN_NAVIGATION  : NAVIGATION

    return (
        <div className={styles.wrapper}>
            <div className={styles.navWrapper}>
                <nav className={styles.nav}>
                    <div>
                        <NavLink activeClassName={styles.activeClassName} to='/' className={styles.logoWrapper}>
                            <img 
                                src="/icons/grey/logo.svg"
                                alt="rad logo"
                                height="40px"
                            />
                            <span>PARTNERS</span>
                        </NavLink>
                        <ul>
                            {navigation.map(
                                ({ path, name, icon }) => (
                                    <li key={`nav-item-${path}`}>
                                        <NavLink activeClassName={styles.activeClassName} to={path} exact>
                                            <Icon 
                                                icon={icon}
                                                size={2}
                                            />
                                            {name}
                                        </NavLink>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                    <ul>
                        <li>
                            <NavLink activeClassName={styles.activeClassName} to="/account">
                                <Icon 
                                    icon="account"
                                    size={2}
                                />
                                Account
                            </NavLink>
                        </li>
                        <li style={{borderBottom: 'none'}}>
                            <a 
                                href="/logout" 
                                onClick={e => {
                                    e.preventDefault()
                                    logout()
                                }}>
                                    <Icon 
                                        icon="logout"
                                        size={2}
                                    />
                                    Logout
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className={styles.mainWrapper}>
                <main>
                    {children}
                </main>
                <Footer/>
            </div>
        </div>
    )
}

export default Dashboard