export const CLOUDINARY_URL = process.env.REACT_APP_ENV === 'production' 
    ? 'https://res.cloudinary.com/dbur3sjwh/image/fetch'
    : 'https://res.cloudinary.com/dbur3sjwh/image/fetch'
export const RADMIN_URL = process.env.REACT_APP_ENV === 'production' 
    ? 'https://curator.rad.travel'
    : 'https://development.d3dqd74q0c8y9p.amplifyapp.com'
export const PED_URL = process.env.REACT_APP_ENV === 'production' 
    ? 'https://ped.rad.travel'
    : 'https://development.d3nl24ud4aahwi.amplifyapp.com'
export const WEBSITE_URL = process.env.REACT_APP_ENV === 'production' 
    ? 'https://website.rad.travel'
    : 'https://website.rad.travel'