import React, { useState } from 'react'
import { Modal, Input, Button } from '../Theme'
import ExplorersList from '../ExplorersList'
import styles from './index.module.css'

const ExplorersModal = ({
    onClose,
    onSelect,
}) => {
    const [requestFilters, setRequestFilters] = useState({
        name: '',
        sortBy: '',
        country: undefined,
        city: undefined,
        categories: undefined,
    })

    return (
        <Modal
            title="Select Explorer"
            showClose={true}
            wrapBody={true}
            onClose={onClose}
            maxWidth={1200}
        >
            <div 
                className={styles.scrollableContainer}
                id="scrollableDiv">    
                <Input
                    placeholder="Search by name"
                    icon="search"
                    onValueChange={value => setRequestFilters({...requestFilters,  'full_name': value })}
                />
                <ExplorersList 
                    filters={requestFilters}
                    actions={false}
                    checkboxEnabled={false}
                    onClickExplorer={onSelect}
                    scrollableTarget="scrollableDiv"
                />
            </div>
        </Modal>
    )
}

export default ExplorersModal