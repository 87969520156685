import React from 'react'
import { ApolloLink } from 'apollo-boost'
import ApolloClient from 'apollo-client'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from '@apollo/react-hooks'
import config from '../config'

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            )
        )
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`)
    }
})

const authLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('token')
    const headers = {}
    
    if (token && token.length) {
        headers['Authorization'] = token
    }

    operation.setContext({ headers })
    
    return forward(operation)
})

const httpLink = createUploadLink({ uri: config.APOLLO_SERVER })

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
        authLink,
        errorLink,
        httpLink,
    ])
})

const withApollo = Component => ({
    ...props
}) => {
    return (
        <ApolloProvider client={client}>
            <Component {...props}/>
        </ApolloProvider>
    )
}

export default withApollo