import React, { useEffect } from 'react'
import Button from '../Button'
import Icon from '../Icon'
import styles from './index.module.css'

const Modal = ({
    onClose,
    children,
    title,
    showClose,
    wrapBody,
    maxWidth = 900,
    height,
}) => {
    useEffect(() => {
        document.querySelector('body').classList.add('no-scroll')

        return () => {
            document.querySelector('body').classList.remove('no-scroll')
        }
    }, [])

    const styleObject = {
        maxWidth,
    }
    if(height) styleObject.height = height

    return (
        <div
            className={styles.modalWrapper}
            onClick={onClose}>
                <div 
                    className={styles.modal}
                    style={styleObject}
                    onClick={e => e.stopPropagation()}>
                        {(title || showClose) && (
                            <div className={styles.header}>
                                <Button onClick={onClose}>
                                    <Icon
                                        icon="large_close"
                                        size={2}
                                    />
                                </Button>
                                {title && (
                                    <h1>{title}</h1>
                                )}
                            </div>
                        )}
                        <div style={{overflow: 'scroll'}} className={wrapBody && styles.body}>
                            {children}
                        </div>
                </div>
        </div>
    )
}

export default Modal