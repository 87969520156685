import React from 'react'
import styles from './index.module.css'
import MediaItem from '../PedMediaItem'
import TextField from '../PedTextField'

const ResultScreenForm = ({
    pedSettings,
    onChange,
}) => {
    return (
        <>
        <div className={styles.form}>    
            <table border-collapse="true" className={styles.table}>
                <thead>
                    <tr>
                        <th>Field</th>
                        <th>Content</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <TextField 
                        label="Result Message"
                        value={pedSettings.resultMessage}
                        onChange={value => onChange({ 'resultMessage': value })}
                    />
                    <TextField 
                        label="Result Subtext"
                        value={pedSettings.resultText}
                        onChange={(value) => onChange({ 'resultText': value })}
                    />
                    <MediaItem 
                        url={pedSettings.airlineLogo}
                        name="Arline Logo"
                        setUrl={(path) => onChange({ 'airlineLogo':path })}
                    />
                </tbody>
            </table>
        </div>
        </>
    )
}

export default ResultScreenForm