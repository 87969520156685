import React, {useState, useRef, useMemo, useEffect} from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation, useLazyQuery, useApolloClient } from '@apollo/react-hooks'
import { Modal, Button, Tabs } from '../Theme'
import Form from './WebsiteSettingForm'
import styles from './index.module.css'
import Loader from "../Loader";
import ImageUploader from '../PedSettingsModal/PedSettingsForms/PedMediaItem/PedImageUploader'

const GET_CITY_COLLECTION = gql`
    query getCityCollection ($id: ID) {
        cityCollection (id: $id) {
            _id,
            name,
            slug,
            _org {
                orgalias,
            },
            website_published,
            pedPublished,
            live_accesstoken,
            test_accesstoken,
        }
    }
`


const GET_CITIES = gql`
    query getCities (
        $name: String,
        $sortBy: String,
        $country: String,
        $page: Int,
        $dataset: String,
    ) {
        cities (
            name: $name,
            sortBy: $sortBy,
            country: $country,
            page: $page,
            dataset: $dataset,
        ) {
            _id,
            name,
            activity_count,
            created_date,
        }
    }
`
const GET_CITY_WEBSITE_SETTINGS = gql`
    query getCitySettings (
        $dataset: String!,
        $cityId: String!,
    ) {
        websitesSettings (
            dataset: $dataset,
            cityId: $cityId,
        ) {
            dataset,
            city_id,
            logo,
            video_cover_url,
            image_cover,
            show_activities,
            show_guides,
            show_categories,
            show_explorers,
        }
    }
`


const CREATE_WEBSITE_SETTINGS = gql`
    mutation createSettings (
        $settings: [WebsiteSettingsReq]
    ) {
        create (
            settings: $settings
        ) {
            success
        }
    }
`

const PUBLISH_COLLECTION = gql`
    mutation publishCityCollection (
        $id: ID!,
        $slug: String!,
        $cities: [CityCollectionCityInput],
    ) {
        publishCityCollection (
            id: $id,
            slug: $slug,
            cities: $cities,
        ) {
            success,
        }
    }
`

const UNPUBLISH_COLLECTION = gql`
    mutation unpublishCityCollection (
        $id: ID!,
    ) {
        unpublishCityCollection (
            id: $id,
        ) {
            success,
        }
    }
`


const WebsiteSettingsModal = ({
    cityCollection,
    published,
    ...props
}) => {

    const {
        loading: citiesLoading,
        data: citiesResponse,
    } = useQuery(GET_CITIES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        variables: {
            dataset: cityCollection.slug,
            page: 1
        },
    })
    const [getCitySettings, {
        // loading: citiesLoading,
        data: citySettingsResponse,
    }] = useLazyQuery(GET_CITY_WEBSITE_SETTINGS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        // variables: {
        //     dataset: cityCollection.slug,
        //     city_id: citiesResponse._id
        // },
    })
    const [ create ] = useMutation(CREATE_WEBSITE_SETTINGS)
    const [ publish ] = useMutation(PUBLISH_COLLECTION, {
        update(cache, data) {

            if(!data?.data?.publishCityCollection?.success) return console.log('cache dot update')

            const cachedData = cache.readQuery({ 
                query: GET_CITY_COLLECTION,
                variables: {
                    id: cityCollection._id,
                  },
             })

             cache.writeQuery({
                id: cityCollection._id,
                query: GET_CITY_COLLECTION,
                data: {
                    cityCollection: {
                        ...cachedData.cityCollection,
                        website_published: true    
                    }
                }
              });

        }
      })
    const [ unpublish ] = useMutation(UNPUBLISH_COLLECTION, {
        update(cache, data) {
            if(!data?.data?.unpublishCityCollection?.success) return console.log('cache dot update')
            const cachedData = cache.readQuery({ 
                query: GET_CITY_COLLECTION,
                variables: {
                    id: cityCollection._id,
                  },
             })

             cache.writeQuery({
                id: cityCollection._id,
                query: GET_CITY_COLLECTION,
                data: {
                    cityCollection: {
                        ...cachedData.cityCollection,
                        website_published: false    
                    }
                }
              });

        }
    })
    const [settings, setSettings] = useState({})
    const [logo, setLogo] = useState()
    const [tab, setTab] = useState();
    const settingsContainer = useRef({})
    const tabs = useMemo(() => {
        if (!citiesResponse)  return []
        return citiesResponse.cities.map(city => city.name)
    }, [citiesResponse])

    const currentCity = useMemo(() =>{
        return (tab && citiesResponse?.cities) 
            ? (citiesResponse.cities.find(city => city.name === tab))
            : null

    }, [tab, citiesResponse])

    useEffect(() => {
        if (citiesResponse) {
            setTab(tabs[0])
        }
    }, [citiesResponse, tabs])

    useEffect(() => {
        console.log(settingsContainer.current[tab], '---ref', tab)
        if (settingsContainer.current[tab]) return setSettings(settingsContainer.current[tab])
        if(!citiesResponse || !citiesResponse.cities || !tab) return

        const currentCity = citiesResponse.cities.find(city => city.name === tab)
        getCitySettings({
            variables: {
                dataset: cityCollection.slug,
                cityId: currentCity._id
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])

    useEffect(() => {
        if(tab) settingsContainer.current[tab] = {
            ...settings,
            dataset: cityCollection.slug,
            cityId: currentCity._id,
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings])

    useEffect(() => {
        console.log(citySettingsResponse)
        if(!citySettingsResponse) return 
        if(citySettingsResponse && !citySettingsResponse.websitesSettings) return setSettings({
            videoCoverUrl: '',
            imageCover: '',
        })

        const settings = {
            videoCoverUrl: citySettingsResponse.websitesSettings.video_cover_url,
            imageCover: citySettingsResponse.websitesSettings.image_cover,
            showGuide: citySettingsResponse.websitesSettings.show_guides,
            showActivities: citySettingsResponse.websitesSettings.show_activities,
            showCategories: citySettingsResponse.websitesSettings.show_categories,
            showExplorers: citySettingsResponse.websitesSettings.show_explorers,
        }
        if(!logo) setLogo(citySettingsResponse.websitesSettings.logo)
        settingsContainer.current[tab] = {
            ...settings,
            dataset: cityCollection.slug,
            cityId: currentCity._id,
        }

        setSettings(settings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [citySettingsResponse])
                
    const submit = () => {
        const requestData = Object.values(settingsContainer.current)

        const publishCities = citiesResponse.cities.map((city) => {
            const {__typename, created_date, ...cityToPublish} = city
            return {
                ...cityToPublish,
                publish_date: created_date,
            }
        })
        create({
            variables: {
                settings: requestData.map(city => ({...city, logo: logo}))
            }
        }).then(()=> publish({
            variables: {
                id: cityCollection._id,
                slug: cityCollection.slug,
                cities: publishCities,
            },
                // update(cache, data) {
                //     console.log(cache, 'cache')
                // }

        }))
        
        props.onClose()
    }

    const onUnpublish = () => unpublish({
            variables: {
                id: cityCollection._id,
            }
        }).then(() => props.onClose(false))

    const onChangeTab = (tab) => {
        setTab(tab)
    }
                
    return (
        <Modal
            title="Hotel Website Settings"
            showClose={true}
            wrapBody={true}
            onClose={() => props.onClose()}
            maxWidth={1200}
            height={"70vh"}
        >
            <div>
                <div className={styles.tabsContainer}>
                    <Tabs
                        activeTab={tab}
                        tabs={tabs}
                        onChange={onChangeTab}
                    />
                </div>
                <div className={styles.formSection}>
                    {
                    !citiesLoading 
                        ? (
                            <>
                            <div className={styles.formControl}>
                                    <label>
                                        Dataset Logo
                                    </label>
                                <div 
                                    className={styles.inputWrapper}>
                                        <ImageUploader  
                                            value={logo}
                                            onValueChange={src => {
                                                setLogo(src)
                                            }}
                                        />
                                </div>
                            </div>
                            <Form
                            settings={settings}
                            onChange={setSettings}
                        /> 
                            </> 
                        )
                        : <Loader />
                    }
                    {published && <div className={styles.unpublishingButtonContainer}>
                        {/* <p className={styles.unpublishingDesc}>
                            Unpublish Hotel Website 
                        </p> */}
                        <Button
                            color="red" 
                            style={{
                                alignSelf: 'flex-end',
                                width: 180
                            }}
                            onClick={() => onUnpublish()}
                            primary>
                                Unpublish
                        </Button>
                    </div>  }
                </div>
                <div className={styles.footer}>
                    <Button
                        color="blcak" 
                        style={{width: 180}}
                        onClick={props.onClose}
                        primary>
                            Cancel
                    </Button>
                    <Button 
                        color="blue" 
                        style={{width: 180}}
                        onClick={submit}
                        primary>
                            Save
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default WebsiteSettingsModal