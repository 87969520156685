import React from 'react'
import Icon from '../Icon'
import styles from './index.module.css'

const Checkbox = ({
    name,
    value,
    children,
    onChange,
}) => {
    const onCheckboxCheck = (e) => {
        onChange(e.target.checked)
    }

    return (
        <div className={styles.checkbox}>
            <label htmlFor={name}>
                <input
                    id={name}
                    name={name}
                    type="checkbox"
                    checked={value}
                    onChange={onCheckboxCheck}
                />
                <span className={styles.box}>
                    <Icon
                        icon="done"
                        size={2}
                    />
                </span>
                {children}
            </label>
        </div>
    )
}

export default Checkbox