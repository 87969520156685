function dataURLtoBlob(dataURL) {
    const BASE64_MARKER = ';base64,';

    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        const parts = dataURL.split(',');
        const contentType = parts[0].split(':')[1];
        const raw = decodeURIComponent(parts[1]);

        return new Blob([raw], {type: contentType});
    }

    const parts = dataURL.split(BASE64_MARKER);
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;

    const uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], {type: contentType});
}

const createImage = (incomeImage) => {

    return new Promise((res, rej) => {
        const image = new Image();
      
        image.onload = function(){
            const canvas=document.createElement("canvas");
            const context=canvas.getContext("2d");

            const ratio = image.height / image.width;
            if (image.width > 800) {
                canvas.width = 800;
                canvas.height = ratio * 800;
            } else {

                canvas.width=image.width
                canvas.height=image.heigh
            }
    
    
            context.drawImage(image,
                0,
                0,
                image.width,
                image.height,
                0,
                0,
                canvas.width,
                canvas.height
            );
            
            const DImage = canvas.toDataURL()
            const BImage = dataURLtoBlob(DImage)
            res(BImage)
        }

        image.src = incomeImage
    })
}


export const fileReader = (file, width, height) => (
    new Promise((res, rej) => {
        const fileRead = new FileReader()
        fileRead.readAsDataURL(file)
    
        fileRead.onload = function (e){
           createImage(e.target.result).then(resulte => res(resulte) )

           
        }
    })
)

