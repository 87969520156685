import React from 'react'
import { Modal, Button, Icon } from '../../Theme'
import styles from './index.module.css'
import ImageUpload from '../../ImageUpload'


const ChangeImageModal = ({
    name,
    value,
    setValue,
    submit,
    onClose,
}) => {
    const onSubmit = () => {
        submit()
        onClose()
    }
    return (
        <Modal onClose={onClose}>
            <div className={styles.header}>
                <Button onClick={onClose}>
                    <Icon
                        icon="large_close"
                        size={2}
                    />
                </Button>
                <h1>Change {name}</h1>
            </div>
            <div className={styles.body}>
               <ImageUpload
                    value={value}
                    name={name}
                    onValueChange={setValue} />
            </div>
            <div className={styles.footer}>
                <Button
                    style={{width: 180}}
                    onClick={onClose}>
                        Cancel
                </Button>
                <Button 
                    color="blue" 
                    style={{width: 180}}
                    onClick={onSubmit}
                    primary>
                        Save
                </Button>
            </div>
        </Modal>
    )
}

export default ChangeImageModal