import React, { useReducer, useState } from 'react'
import { gql } from 'apollo-boost'
import { message } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { Modal, Input, Button } from '../../Theme'
import styles from './index.module.css'

const UPLOAD_VIDEO = gql`
    mutation uploadVideo (
        $video: Upload,
    ) {
        uploadVideo (
            video: $video,
        ) { 
            _id
        }
    }
`

const UPLOAD_VIDEO_URL = gql`
    mutation uploadVideoUrl (
        $url: String,
    ) {
        uploadVideoUrl (
            url: $url,
        ) {
            _id,
        }
    }
`

const VideoModal = ({
    onClose,
    reload,
}) => {
    const [uploadVideo] = useMutation(UPLOAD_VIDEO)
    const [uploadVideoUrl, { loading: uploadingVideo }] = useMutation(UPLOAD_VIDEO_URL)
    const [method, setMethod] = useState(undefined)
    const [url, setUrl] = useState('')
    const [video, set] = useReducer(
        (state, [field, value]) => ({...state, [field]: value}),
        {
            // name: '',
            // description: '',
            video: undefined,
        }
    )

    const save = () => {
        if (method === 'url') {
            uploadVideoUrl({ variables: { url } })
                .then(() => {
                    reload()
                    onClose()
                })
                .catch(console.error)
            return
        }

        message.success('Video being uploaded...')
        uploadVideo({ variables: video })
            .catch(console.error)
        onClose()
    }

    return (
        <Modal
            title="Add Video"
            showClose={true}
            wrapBody={true}
            onClose={onClose}
        >
            <div>
                {method === undefined ? (
                    <div className={styles.selection}>
                        <Button
                            color="blue"
                            style={{width: 180}}
                            onClick={() => setMethod('url')}
                            bordered>
                                Add a URL
                        </Button>
                        <span>
                            or
                        </span>
                        <Button
                            color="blue"
                            style={{width: 180}}
                            onClick={() => setMethod('upload')}
                            bordered>
                                Upload
                        </Button>

                        <div>
                            <Button
                                style={{width: 180}}
                                onClick={onClose}>
                                    Cancel
                            </Button>
                        </div>
                    </div>
                ) : (
                    <>
                        {method === 'url' ? (
                            <Input 
                                label="URL"
                                value={url}
                                placeholder="https://vimeo.com/videos/XXXXXXXXX"
                                onChange={e => setUrl(e.target.value)}
                                onEnter={save}
                            />
                        ) : (
                            <>
                                <Input 
                                    label="Name"
                                    value={video.name}
                                />
                                <Input 
                                    label="Description"
                                    value={video.description}
                                />
                                <div className="mb-4">
                                    <input 
                                        type="file"
                                        onChange={e => set(['video', e.target.files[0]])}
                                    />
                                </div>
                            </>
                        )}

                        <div className={styles.footer}>
                            <Button 
                                style={{width: 180}}
                                onClick={() => setMethod(undefined)}>
                                    Back
                            </Button>
                            <Button 
                                style={{width: 180}}
                                onClick={save}
                                loading={uploadingVideo}
                                primary>
                                    Save
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    )
}

export default VideoModal