import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { Modal, Button, Input } from '../../Theme'
import { RADMIN_URL } from '../../../constants'
import styles from './index.module.css'

const CREATE_EXPLORER = gql`
    mutation createExplorer (
        $email: String!,
        $password: String!,
    ) {
        createExplorer (
            email: $email,
            password: $password,
        ) {
            _id,
            email,
        }
    }
`

const CreateExplorerModal = ({

    onClose
}
) => {
    const [createExplorer, {data: explorerResponse, loading }] = useMutation(CREATE_EXPLORER)
    const [explorerData, setExplorerData] = useState({})
    const submit = () => {
        if(explorerData.password !== explorerData.confirmPassword) return message.error("Passwords don't match")
        createExplorer({
            variables: {
                email: explorerData.email,
                password: explorerData.password
            }
        }).catch(e => {
            const error = e.graphQLErrors && e.graphQLErrors[0]
            console.log(error)
            error 
                ? error.message 
                    ? message.error(error.message)
                    : message.error(error) 
                        : message.error(e.message)
        })
    }

    useEffect(() => {
        if(!explorerResponse) return 
        const url = `${RADMIN_URL}/e/${explorerResponse.createExplorer.email}?org_token=${localStorage.getItem('token')}&redirect_url=${window.location.href}&editable=true`
       
        window.open(url)
    }, [explorerResponse])

    return (
        <Modal
            title="Create Explorer"
            showClose={true}
            wrapBody={true}
            onClose={() => onClose()}
            maxWidth={1200}
        >
            <div>
                <div className={styles.body}>
                    <div className={styles.form}>    
                        <Input
                            label="Email"
                            value={explorerData.email}
                            required="Email required"
                            onChange={e => setExplorerData({
                                ...explorerData,
                                email: e.target.value, 
                            })}
                        />
                        <Input 
                            label="Password"
                            value={explorerData.password}
                            type="password"
                            required="Name required"
                            onChange={e =>  setExplorerData({
                                ...explorerData,
                                password: e.target.value, 
                            })}
                        />
                        <Input 
                            label="Confirm Password"
                            value={explorerData.confirmPassword}
                            type="password"
                            required="Name required"
                            onChange={e =>  setExplorerData({
                                ...explorerData,
                                confirmPassword: e.target.value, 
                            })}
                        />
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button
                        style={{width: 180}}
                        onClick={() => onClose()}>
                            Cancel
                    </Button>
                    <Button 
                        color="blue" 
                        style={{width: 180}}
                        onClick={() => submit()}
                        loading={loading}
                        primary>
                            Save
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default CreateExplorerModal