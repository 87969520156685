import React from 'react'
import styles from './index.module.css'

const map = <path style={{
    strokeWidth: '2px'

}} d="M21 6l-10-4-11 4v24l11-4 10 4 11-4v-24l-11 4zM12 4.554l8 3.2v19.692l-8-3.2v-19.692zM2 7.401l8-2.909v19.744l-8 2.909v-19.744zM30 24.599l-8 2.909v-19.744l8-2.909v19.744z"></path>
const yelp = <path d="M19.027 20.421c-0.54 0.545-0.083 1.535-0.083 1.535l4.065 6.788c0 0 0.668 0.895 1.246 0.895 0.581 0 1.155-0.477 1.155-0.477l3.214-4.594c0 0 0.324-0.58 0.331-1.087 0.012-0.722-1.077-0.92-1.077-0.92l-7.609-2.444c-0 0-0.746-0.198-1.242 0.303zM18.642 17c0.389 0.66 1.463 0.468 1.463 0.468l7.592-2.219c0 0 1.035-0.421 1.182-0.982 0.145-0.561-0.171-1.238-0.171-1.238l-3.628-4.274c0 0-0.314-0.54-0.967-0.595-0.719-0.061-1.162 0.809-1.162 0.809l-4.29 6.75c0 0-0.379 0.672-0.020 1.28zM15.055 14.368c0.894-0.22 1.036-1.519 1.036-1.519l-0.061-10.808c0 0-0.135-1.333-0.734-1.695-0.94-0.57-1.218-0.272-1.487-0.233l-6.303 2.342c0 0-0.617 0.204-0.939 0.719-0.459 0.73 0.466 1.798 0.466 1.798l6.551 8.93c0 0 0.647 0.669 1.47 0.465zM13.498 18.742c0.023-0.834-1.001-1.334-1.001-1.334l-6.775-3.423c0 0-1.004-0.414-1.491-0.126-0.373 0.221-0.704 0.62-0.737 0.973l-0.441 5.432c0 0-0.066 0.941 0.178 1.369 0.345 0.608 1.482 0.184 1.482 0.184l7.909-1.748c0.307-0.207 0.846-0.225 0.876-1.328zM15.465 21.673c-0.679-0.349-1.491 0.373-1.491 0.373l-5.296 5.83c0 0-0.661 0.892-0.493 1.439 0.158 0.513 0.42 0.768 0.791 0.948l5.319 1.679c0 0 0.645 0.134 1.133-0.008 0.693-0.201 0.565-1.286 0.565-1.286l0.12-7.894c-0 0-0.027-0.76-0.648-1.082z"></path>
const location = <path d="M16 0c-5.523 0-10 4.477-10 10 0 10 10 22 10 22s10-12 10-22c0-5.523-4.477-10-10-10zM16 16c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6z"></path>


const svgs = {
    map, 
    yelp,
    location
}

const Icon = ({
    icon,
    size = 1,
}) => {
    return svgs[icon] ? (
        <svg 
        width="26" height="26"
        viewBox="-5 5 40 30"
        fontSize="normal"
        x="0px" y="0px"
            // className={styles.icon}
            style={{
                    marginRight: '20px',
                    width: '26px',
                    height: '26px',    
                    display: 'inline-block',
                    fill: '#6f6f6f',
                    fontSize: `${size}rem`
                }}>

                {svgs[icon]}
        </svg>
    ) : (
        <i 
        className={styles.icon}
        style={{fontSize: `${size}rem`}}>
            {icon}
    </i>
    )
}

export default Icon