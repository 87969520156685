import React, { useState, useReducer, useRef } from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useForceUpdate } from '../../hooks'
import { TOGGLE_ITEMS_IN_CITY_COLLECTION } from '../../mutations'
import { Input, Switch, Button, Icon } from '../Theme'
import CityCountryFilters from '../CityCountryFilters'
import Page from '../Page'
import GuidesList from '../GuidesList'
import SelectCityCollectionModal from '../SelectCityCollectionModal'
import ExplorersModal from '../ExplorersModal'
import styles from './index.module.css'
import LoadingContainer from '../LoadingContainer'
import { useUser } from '../../hocs/auth'
import { RADMIN_URL } from '../../constants'

const GET_GUIDES_COUNT = gql`
    query getGuidesCount (
        $name: String,
        $city: String,
        $country: String,
    ) {
        guidesCount (
            name: $name,
            city: $city,
            country: $country,
        )
    }
`

const Guides = () => {
    const { user } = useUser()
    const [selectCollection, toggleSelectCollection] = useState(false)
    const [explorersModal, toggleExplorersModal] = useState(false)
    const [toggleItems, { loading: adding }] = useMutation(TOGGLE_ITEMS_IN_CITY_COLLECTION)
    const [selectedCount, setSelectedCount] = useState(0)
    const selected = useRef(new Set())
    const [reloaded, reload] = useForceUpdate()

    const onSelectExplorer = (explorerId) => {
        const url = `${RADMIN_URL}/create/guide?org_token=${localStorage.getItem('token')}&redirect_url=${window.location.href}&explorer_id=${explorerId}`
        window.open(url)
    }

    

    const [filters, setFilters] = useReducer(
        (state, value) => ({ ...state, ...value }),
        {
            name: '',
            sortBy: '',
            country: undefined,
            city: undefined,
        }
    )

    const {
        data: guidesCountResponse,
        refetch: recountGuides,
        loading
    } = useQuery(GET_GUIDES_COUNT)

    const filter = (value) => {
        setFilters(value)
        recountGuides({ ...filters, ...value })
            .catch(console.error)
    }

    const onSelect = (id) => {
        if (selected.current.has(id)) {
            selected.current.delete(id)
        } else {
            selected.current.add(id)
        }

        setSelectedCount(
            [...selected.current].length
        )
    }

    const add = (collections) => {
        Promise.all(
            collections.map(
                id => toggleItems({
                    variables: {
                        id,
                        ids: [...selected.current],
                        model: 'Guide',
                    }
                })
            )
        ).then(() => {
            reload()
            selected.current = new Set()
            setSelectedCount(0)
        })
            .catch(console.error)
    }

    return (
        <Page
            title="Guides"
            icon="experiences"
            help={{
                title: 'How Guides Work',
                description: 'This section allows you to add Guides to your dataset.',
                sections: [
                    [
                        'Curate:',
                        <p>Search Guides with the search box or sort by Name/Date/Filter.  When you locate the Guides you want in your Dataset, click the checkbox to include them.</p>
                    ],
                    [
                        'Implement:',
                        <p>When you feel good about your list, click ADD ITEMS TO DATASET+ below to add all checked items, then name your Dataset. If you have any questions, <a href="mailto:masha@rad.travel">email us</a>. We are here for you.</p>
                    ]
                ]
            }}
            buttons={[
                <Button 
                    key="add-to-dataset"
                    onClick={() => toggleSelectCollection(true)}
                    disabled={selectedCount === 0 || undefined}
                    loading={adding}
                    primary>
                        <Icon 
                            icon="add"
                            size={2}
                        />
                        Add {selectedCount ? selectedCount : ''} Guides To Dataset
                </Button>,
                (user.isAdmin || user?.permissions?.createGuide) && <Button 
                key="add-guide"
                onClick={() => toggleExplorersModal(true)}
                disabled={!user.isAdmin && !user?.permissions?.createGuide}
                loading={adding}
                primary>
                    <Icon 
                        icon="add"
                        size={2}
                    />
                    Create Guide
                </Button>,
            ]}>
                {explorersModal && <ExplorersModal
                    onClose={() => toggleExplorersModal(false)}
                    filters={{}}
                    onSelect={onSelectExplorer}
                 />}
                <div>
                    <Input
                        placeholder="Search by name"
                        icon="search"
                        onValueChange={value => filter({ 'name': value })}
                    />

                    <div>
                        <span className="mr-2">Sort By</span>
                        <Switch
                            value="name"
                            onChange={value => filter({ 'sortBy': value })}
                            values={[
                                ['Name', 'name'],
                                ['Date', 'updated_date'],
                            ]}
                        />
                    </div>

                    <CityCountryFilters
                        cityValue={filters.city}
                        countryValue={filters.country}
                        onCityChange={filter}
                        onCountryChange={filter}
                    />
                </div>

                <div className={styles.refreshBar}>
                    {guidesCountResponse && (
                        <h3>{guidesCountResponse.guidesCount} Guides</h3>
                    )}
                </div>

                <LoadingContainer loading={loading}>
                    {reloaded && (
                        <GuidesList
                            source="Guides"
                            filters={filters}
                            onSelect={onSelect}
                        />
                    )}
                </LoadingContainer>

                {selectCollection && (
                    <SelectCityCollectionModal
                        onAdd={add}
                        onClose={() => toggleSelectCollection(false)}
                    />
                )}
        </Page>
    )
}

export default Guides