import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Modal, Button, Input } from '../../Theme'
import styles from './index.module.css'

const CREATE_CITY_COLLECTION = gql`
    mutation createCityCollection (
        $name: String!,
    ) {
        createCityCollection (
            name: $name,
        ) {
            _id,
        }
    }
`

const CreateCityCollectionModal = ({
    reload,
    toggleAddCities,
    onClose,
}) => {
    const [name, setName] = useState('')
    const [createCityCollection, { loading }] = useMutation(CREATE_CITY_COLLECTION)

    const submit = () => {
        createCityCollection({ variables: { name } })
            .then(response => {
                toggleAddCities(response.data.createCityCollection._id)
                reload()
                onClose(true)
            })
            .catch(console.error)
    }

    return (
        <Modal
            title="New Dataset"
            showClose={true}
            wrapBody={true}
            onClose={() => onClose(false)}
        >
            <div className={styles.body}>
                <h2>Enter a name for your dataset</h2>
                <Input 
                    label="Dataset Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    onEnter={submit}
                />
                <div className={styles.footer}>
                    <Button
                        style={{width: 180}}
                        onClick={() => onClose(false)}>
                            Cancel
                    </Button>
                    <Button 
                        color="blue" 
                        style={{width: 180}}
                        onClick={submit}
                        loading={loading}
                        primary>
                            Save
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default CreateCityCollectionModal