import { useState } from 'react'

const useForceUpdate = () => {
    const [value, setValue] = useState(true)
    
    return [
        value,
        () => {
            setValue(false)
            setTimeout(() => {
                setValue(true)
            }, 0)
        }
    ]
}

export default useForceUpdate