import React from 'react'
import styles from './index.module.css'

const Button = ({
    onClick,
    primary,
    children,
    style,
    color,
    loading,
    disabled,
    bordered,
}) => {
    return (
        <button
            data-primary={primary}
            data-color={color}
            data-loading={loading}
            data-bordered={bordered && color}
            onClick={onClick}
            className={styles.button}
            style={style}
            disabled={loading || disabled}>
                {loading ? 'Loading...' : children}
        </button>
    )
}

export default Button