import React from 'react'
import styles from './index.module.css'
import MediaItem from '../PedMediaItem'
import TextField from '../PedTextField'

const TravelingForForm = ({
    pedSettings,
    onChange,
}) => {
    return (
        <>
        <div className={styles.form}> 
        <table border-collapse="true" className={styles.table}>
            <thead>
                <tr>
                    <th>Field</th>
                    <th>Content</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <TextField
                    label="Quetion"
                    onChange={value => {
                        onChange({'travelingForText': value})}
                    }
                    value={pedSettings.travelingForText}
                /> 
                <MediaItem 
                        name="Cover"
                        url={pedSettings.travelingForImage}
                        setUrl={(path) => onChange({ 'travelingForImage': path })}
                />
            </tbody>
        </table>
        </div>
        </>
    )
}

export default TravelingForForm