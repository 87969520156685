import React from "react";
import styles from './index.module.css'
import { Input} from '../../../Theme'

const TextField = ({label, value, onChange}) => {
    return (
            <tr>
                <td className={styles.descCol}>
                    <div className={styles.description}>
                        <h3>{label}</h3>
                    </div>
                </td>
                <td className={styles.inputCol}>
                    <Input
                            onChange={e => {
                                onChange(e.target.value)}
                            }
                            value={value}
                    />
                </td>
                <td className={styles.inputCol}>
                </td>
            </tr>
    )
}

export default TextField