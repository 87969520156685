import React from 'react'
import { Input} from '../../Theme'
import styles from '../index.module.css'

const OrganizationForm = ({
    organization,
    onChange
}) => {
    return (
       <div className={styles.form}>    
            <Input
                label="Partner Name"
                value={organization.orgname}
                required="Name required"
                onChange={e => onChange({
                                'orgname': e.target.value, 
                                'orgalias': e.target.value.replace(/ /g,"-").toLowerCase()
                                })}
            />
            <Input 
                label="Partner Alias"
                value={organization.orgalias}
                onChange={e => onChange({ 'orgalias': e.target.value })}

            />
            <Input 
                label="First Name"
                value={organization.firstName}
                onChange={(e) => onChange({ 'firstName': e.target.value })}
            />
            <Input 
                label="Last Name"
                value={organization.lastName}
                onChange={(e) => onChange({ 'lastName': e.target.value })}
            />
            <Input 
                label="Email"
                value={organization.email}
                onChange={(e) => onChange({ 'email': e.target.value })}
            />
            <Input 
                label="Password"
                value={organization.password}
                type='password'
                onChange={(e) => onChange({ 'password': e.target.value })}
            />
            <Input 
                label="Repeat new Password"
                type='password'
                value={organization.confirmPassword}
                onChange={(e) => onChange({ 'confirmPassword': e.target.value })}
            />
        </div>
    )
}

export default OrganizationForm