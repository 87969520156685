import React, { useReducer, useRef, useState } from 'react'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { TOGGLE_ITEMS_IN_CITY_COLLECTION } from '../../mutations'
import { useForceUpdate } from '../../hooks'
import { Input, Switch, Button, Icon } from '../Theme'
import CityCountryFilters from '../CityCountryFilters'
import Page from '../Page'
import CitiesList from '../CitiesList'
import SelectCityCollectionModal from '../SelectCityCollectionModal'
import styles from './index.module.css'
import LoadingContainer from '../LoadingContainer'

const GET_CITIES_COUNT = gql`
  query getCitiesCount(
      $country: String,
      $dataset: String,
  ) {
      citiesCount(
        country: $country,
        dataset: $dataset
      )
  }
`

const Cities = () => {
    const selected = useRef(new Set())
    const [selectedCount, setSelectedCount] = useState(0)
    const [selectCollection, toggleSelectCollection] = useState(false)
    const [reloaded, reload] = useForceUpdate()
    const [toggleItems, { loading: adding }] = useMutation(TOGGLE_ITEMS_IN_CITY_COLLECTION)

    const [filters, setFilters] = useReducer(
        (state, value) => ({...state, ...value}),
        {
            name: '',
            sortBy: '',
            country: undefined,
            city: undefined,
        }
    )

    const { data: citiesCountResponse, refetch: recountCities, loading } = useQuery(GET_CITIES_COUNT)

    const filter = ( value) => {
      setFilters(value)
      recountCities(value).catch(console.error)
    }

    const onSelect = (id) => {
        if (selected.current.has(id)) {
            selected.current.delete(id)
        } else {
            selected.current.add(id)
        }

        setSelectedCount([...selected.current].length)
    }

    const add = (collections) => {
        Promise.all(
            collections.map(
                id => toggleItems({
                    variables: {
                        id,
                        ids: [...selected.current],
                        model: 'City',
                    }
                })
            )
        ).then(() => {
            reload()
            selected.current = new Set()
            setSelectedCount(0)
        })
        .catch(console.error)
    }

    console.log("#art:city", citiesCountResponse);
    

    return (
        <Page
            title="Cities"
            icon="experiences"
            help={{
                title: 'How Cities Work',
                description: 'This section allows you to add Cities to your dataset.',
                sections: [
                    [
                        'Curate:',
                        <p>Search Cities with the search box or sort by Name/Date/Filter.  When you locate the Cities you want in your Dataset, click the checkbox to include them.</p>
                    ],
                    [
                        'Implement:',
                        <p>When you feel good about your list, click ADD ITEMS TO DATASET+ below to add all checked items, then name your Dataset. If you have any questions, <a href="mailto:masha@rad.travel">email us</a>. We are here for you.</p>
                    ]
                ]
            }}
            buttons={[
                <Button 
                    key="add-to-dataset"
                    onClick={() => toggleSelectCollection(true)}
                    disabled={selectedCount === 0 || undefined}
                    loading={adding}
                    primary>
                        <Icon 
                            icon="add"
                            size={2}
                        />
                        Add {selectedCount ? selectedCount : ''} Cities To Dataset
                </Button>,
            ]}>
                <div>
                    <Input
                        placeholder="Search by name"
                        icon="search"
                        onValueChange={value => filter({ 'name': value })}
                    />

                    <div>
                        <span className="mr-2">Sort By</span>
                        <Switch
                            value="name"
                            onChange={value => filter({ 'sortBy': value })}
                            values={[
                                ['Name', 'name'],
                                ['Date', 'updated_date'],
                            ]}
                        />
                    </div>

                    <CityCountryFilters
                        countryValue={filters.country}
                        onCountryChange={filter}
                    />
                </div>

                <div className={styles.refreshBar}>
                    {citiesCountResponse && (
                        <h3>{citiesCountResponse.citiesCount} Cities</h3>
                    )}
                </div>

                <LoadingContainer loading={loading} >
                    {reloaded && (
                        <CitiesList
                            filters={filters}
                            onSelect={onSelect}
                        />
                    )}
                </LoadingContainer>

                {selectCollection && (
                    <SelectCityCollectionModal
                        onAdd={add}
                        onClose={() => toggleSelectCollection(false)}
                    />
                )}
        </Page>
    )
}

export default Cities