import React, { useRef, useState, useReducer } from 'react'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { useQuery } from '@apollo/react-hooks'
import { useForceUpdate } from '../../hooks'
import { TOGGLE_ITEMS_IN_CITY_COLLECTION } from '../../mutations'
import { Input, Switch, Button, Icon } from '../Theme'
import Page from '../Page'
import ExperiencesList from '../ExperiencesList'
// import SelectModal from '../SelectModal'
import CityCountryFilters from '../CityCountryFilters'
import SelectCityCollectionModal from '../SelectCityCollectionModal'
import ExplorersModal from '../ExplorersModal'
import styles from './index.module.css'
import LoadingContainer from '../LoadingContainer'
import { useUser } from '../../hocs/auth'
import { RADMIN_URL } from '../../constants'

// const GET_CATEGORIES = gql`
//     query getActivitiesCategories (
//         $name: String,
//         $country: String,
//         $city: String,
//     ){
//         activitiesCategories (
//             city: $city,
//             country: $country,
//             name: $name,
//         ) {
//             name,
//             activity_count
//         }
//     }
// `

const GET_ACTIVITIES_COUNT = gql`
    query getActivitiesCount (
        $city: String,
        $country: String,
        $name: String,
        $categories: [String],
    ) {
        activitiesCount (
            city: $city,
            country: $country,
            name: $name,
            categories: $categories,
        )
    }
`


const Experiences = () => {
    const { user } = useUser()
    const [explorersModal, toggleExplorersModal] = useState(false)
    // const [showCategoriesSelect, setCategoriesSelect] = useState(false)
    const [selectCollection, toggleSelectCollection] = useState(false)
    const [toggleItems, { loading: adding }] = useMutation(TOGGLE_ITEMS_IN_CITY_COLLECTION)
    const [selectedCount, setSelectedCount] = useState(0)
    const selected = useRef(new Set())
    const [reloaded, reload] = useForceUpdate()

    const [filters, setFilters] = useReducer(
        (state, value) => ({...state, ...value}),
        {
            name: '',
            sortBy: '',
            country: undefined,
            city: undefined,
            // categories: undefined,
        }
    )
    const {
        data: activitiesCountResponse,
        refetch: recountActivities,
        loading
    } = useQuery(GET_ACTIVITIES_COUNT)

    const filter = (value) => {
        setFilters(value)
        // categoriesRefetch({...filters, ...value})
        recountActivities({...filters, ...value})
            .catch(console.error)
    }

    const onSelect = (id) => {
        if (selected.current.has(id)) {
            selected.current.delete(id)
        } else {
            selected.current.add(id)
        }

        setSelectedCount(
            [...selected.current].length
        )
    }

    const onSelectExplorer = (explorerId) => {
        const url = `${RADMIN_URL}/create/activity?org_token=${localStorage.getItem('token')}&redirect_url=${window.location.href}&explorer_id=${explorerId}`
        window.open(url)
    }

    const add = (collections) => {
        Promise.all(
            collections.map(
                id => toggleItems({
                    variables: {
                        id,
                        ids: [...selected.current].map(i => i._id),
                        model: 'Activity',
                    }
                })
            )
        ).then(() => {
            reload()
            selected.current = new Set()
            setSelectedCount(0)
        })
        .catch(console.error)
    }

    return (
        <Page
            title="Experiences"
            icon="experiences"
            help={{
                title: 'How Experiences Work',
                description: 'This section allows you to add Experiences to your dataset.',
                sections: [
                    [
                        'Curate:',
                        <p>Search Experiences with the search box or sort by Name/Date/Filter.  When you locate the Experiences you want in your Dataset, click the checkbox to include them.</p>
                    ],
                    [
                        'Implement:',
                        <p>When you feel good about your list, click ADD ITEMS TO DATASET+ below to add all checked items, then name your Dataset. If you have any questions, <a href="mailto:masha@rad.travel">email us</a>. We are here for you.</p>
                    ]
                ]
            }}
            buttons={[
                <Button 
                    key="add-to-dataset"
                    onClick={() => toggleSelectCollection(true)}
                    disabled={selectedCount === 0 || undefined}
                    loading={adding}
                    primary>
                        <Icon 
                            icon="add"
                            size={2}
                        />
                        Add {selectedCount ? selectedCount : ''} Experiences To Dataset
                </Button>,
                (user.isAdmin || user?.permissions?.createActivity) && <Button 
                    key="add-to-experience"
                    onClick={() => toggleExplorersModal(true)}
                    loading={adding}
                    primary>
                        <Icon 
                            icon="add"
                            size={2}
                        />
                        Add Experience
                </Button>,
                // (user.isAdmin || user?.permissions?.deleteActivity) && <Button 
                //     key="delete-experience"
                //     onClick={onDeleteActivities}
                //     disabled={selectedCount === 0 || undefined}
                //     loading={deleteLoading}
                //     primary>
                //         <Icon 
                //             icon="delete"
                //             size={2}
                //         />
                //         Delete Experiences
                // </Button>,
            ]}>
                {explorersModal && <ExplorersModal
                    onClose={() => toggleExplorersModal(false)}
                    filters={{}}
                    onSelect={onSelectExplorer}
                 />}
                <div>
                    <Input
                        placeholder="Search By Keyword"
                        icon="search"
                        onValueChange={value => filter({ 'name': value })}
                    />

                    <div className={styles.filtersSecondRow}>
                        <div>
                            <span className="mr-2">Sort By</span>
                            <Switch
                                value="updated_date"
                                onChange={value => filter({ 
                                    'sortBy': value,
                                    sortDirection: value === 'updated_date' ? -1 : 1
                                })}
                                values={[
                                    ['Date', 'updated_date'],
                                    ['Name', 'name'],
                                ]}
                            />
                        </div>
                        {/* <div>
                            <span className="mr-1">Filter:</span>
                            <Button 
                                onClick={() => setCategoriesSelect(true)}
                                style={{textTransform: 'none'}}>
                                    {filters.categories ? filters.categories.map(
                                        category => (
                                            <span 
                                                key={`category-${category}`}
                                                className="mr-1">
                                                    {category}
                                            </span>
                                        )
                                    ) : (
                                        <span>Select</span>
                                    )}
                            </Button>
                        </div> */}
                    </div>
                    
                    <CityCountryFilters
                        cityValue={filters.city}
                        countryValue={filters.country}
                        onCityChange={filter}
                        onCountryChange={filter}
                    />
                </div>

                <div className={styles.refreshBar}>
                    {activitiesCountResponse && (
                        <h3>{activitiesCountResponse.activitiesCount} Experiences</h3>
                    )}
                </div>

                <LoadingContainer loading={loading}>
                    {reloaded && (
                        <ExperiencesList
                            filters={filters}
                            onSelect={onSelect}
                        />
                    )}
                </LoadingContainer>

                {/* {categoriesResponse && showCategoriesSelect && (
                    <SelectModal
                        title="Filter by Category"
                        submitText="Apply Filters"
                        preselected={filters.categories}
                        onClose={() => setCategoriesSelect(false)}
                        onSubmit={onCategoriesSubmit}
                        values={categoriesResponse.activitiesCategories.map(
                            category => ({
                                name: category.name,
                                value: category.activity_count
                            })
                        )}
                    />
                )} */}

                {selectCollection && (
                    <SelectCityCollectionModal
                        onAdd={add}
                        onClose={() => toggleSelectCollection(false)}
                        reload={reload}
                    />
                )}
        </Page>
    )
}

export default Experiences