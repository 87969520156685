import React from 'react'
import Loader from '../../../../Loader'
import styles from './index.module.css'
import useImageUploader from '../../../../../hooks/useImageUpload'
import { fileReader } from './resizer'

const ImageUpload = ({
    resize = false,
    value,
    name,
    onValueChange,
}) => {
    const {uploader, loading} = useImageUploader();

    const upload = async (e) => {
        let file = e.target.files[0]
        const name = e.target.files[0].name

        if(resize) {
            file = await fileReader(e.target.files[0])
            file.name = name

        }
        
        return uploader(file)
        .then(response => onValueChange(response.Location))
        .catch(console.error)
        
    }


    return (
                <div>
                    <label className={styles.upload}>
                        {loading && (
                            <Loader className={styles.loader} />
                        )}
                        {!loading && (<img 
                            src={value || '/placeholder.png'}
                            alt={name}
                        />)}
                        <input 
                            type="file"
                            onChange={upload}
                        />
                    </label>
                </div>
    )
}

export default ImageUpload