import React, { useState, useRef } from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import Select from 'antd/es/select'
import styles from './index.module.css'

const GET_CITIES = gql`
    query getCities (
        $dataset: String,
        $explorer: String,
    ) {
        cities (
            dataset: $dataset,
            explorer: $explorer,
        ) {
            _id,
            name,
            country
        }
    }
`

const { Option } = Select

const CityCountryFilters = ({
    countryValue,
    cityValue,
    onCityChange,
    onCountryChange,
    disabled = {},
    filters,
}) => {
    const [cities, setCities] = useState([])
    const [countries, setCountries] = useState([])
    const cityCountries = useRef()

    useQuery(GET_CITIES, {
        variables: filters,
        onCompleted: (data) => {
            cityCountries.current = {}
            setCities([
                ...new Set(
                    data.cities.map(
                        city => {
                            cityCountries.current[city.name] = city.country
                            return city.name
                        }
                    )
                )
            ])

            const countries = [
                ...new Set(
                    data.cities.map(
                        city => city.country
                    )
                )
            ]

            countries.sort(
                (a, b) => {
                    if(a < b) return -1
                    if(a > b) return 1
                    return 0
                }
            )

            setCountries(countries)
        }
    })

    const onCloseClick = () => {
        // onCityChange(undefined)
        // setTimeout(() => {
        //     onCountryChange(undefined)
        // }, 1000)
    }

    return (
        <div className={styles.filters}>
            {onCountryChange && (
                <div>
                    <span className="mr-2">Country</span>
                    <Select
                        value={countryValue}
                        onChange={(value) => onCountryChange({ country: value, city: undefined })}
                        style={{width: 200}}
                        placeholder="Select Country"
                        disabled={disabled.country}
                        clearIcon={
                            <img
                                src="/icons/grey/close.svg"
                                alt="close icon"
                                width="24px"
                                onClick={onCloseClick} />
                        }
                        allowClear>
                            {countries.map(
                                (country) => (
                                    <Option 
                                        key={`country-${country}`}
                                        value={country}>
                                            {country}
                                    </Option>
                                )
                            )}
                    </Select>
                </div>
            )}

            {onCityChange && (
                <div>
                    <span className="mr-2">City</span>
                    <Select
                        onChange={(value) => onCityChange({ country: countryValue, city: value })}
                        style={{width: 200}}
                        value={cityValue}
                        placeholder="Select City"
                        disabled={disabled.city}
                        allowClear>
                            {cities.filter(
                                city => {
                                    if (countryValue && countryValue.length) {
                                        return cityCountries.current[city] === countryValue
                                    }

                                    return true
                                }
                            ).map(
                                (city) => (
                                    <Option
                                        key={`city-${city}`}
                                        value={city}>
                                            {city}
                                    </Option>
                                )
                            )}
                    </Select>
                </div>
            )}
        </div>
    )
}

export default CityCountryFilters