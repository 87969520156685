import React, { useState, useMemo, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import { Checkbox } from '../Theme'
import { useEffectSkipFirst, useForceUpdate } from '../../hooks'
import { useUser } from '../../hocs/auth'
import Loader from '../Loader'
import { CLOUDINARY_URL, RADMIN_URL } from '../../constants'
import styles from './index.module.css'
import EmptyResult from '../EmptyResult'

const GET_GUIDES = gql`
    query getGuides (
        $name: String,
        $city: String,
        $country: String,
        $sortBy: String,
        $page: Int,
        $dataset: String,
    ) {
        guides (
            name: $name,
            city: $city,
            country: $country,
            sortBy: $sortBy,
            page: $page,
            dataset: $dataset,
        ) {
            _id,
            name,
            description,
            cover {
                standard_resolution {
                    url
                }
            },
            _explorer {
                _id,
                full_name,
                email
            },
            updated_date,
        }
    }
`
const DELETE_GUIDES = gql`
    mutation deleteGuides (
        $id: ID!,
    ) {
        deleteGuides (id: $id) {
            _id
    }
}
`

const GuidesList = ({
    source,
    filters,
    onSelect,
}) => {
    // let history = useHistory()
    const { user } = useUser()
    const [reloaded, reload] = useForceUpdate()

    const [page, setPage] = useState(1)
    const token = useMemo(() => {
        return localStorage.getItem('token')
    }, [])

    const {
        loading: guidesLoading,
        data: guidesResponse,
        fetchMore: fetchMoreGuides,
        refetch: loadGuides,
    } = useQuery(GET_GUIDES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        variables: filters,
    })
    const[deleteGuides, { loading: deleteLoading }] = useMutation(DELETE_GUIDES)
    const onDeleteGuide = (id) => {
        const confirmDelete = window.confirm("Are you sour you want delete selected Activities")
        if(confirmDelete) {
            return deleteGuides({
                variables: {
                    id: id,
                }
            })
            .then(() => reload())
        } 

    }
    const loadMoreGuides = () => {
        fetchMoreGuides({
            variables: {
                page: page + 1
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev
                }

                setPage(
                    page => page + 1
                )

                return Object.assign(
                    {},
                    prev,
                    {
                        guides: [
                            ...prev.guides,
                            ...fetchMoreResult.guides
                        ]
                    }
                );
            }
        })
    }

    const onImageError = (e) => {
        e.target.src = "https://res.cloudinary.com/dbur3sjwh/image/upload/v1590513468/blur_fsxeji.jpg"
    }

    useEffectSkipFirst(() => {
        loadGuides(filters)
            .catch(console.error)
    }, [filters])

    useEffect(() => {
        if(guidesResponse) loadGuides()
    }, [reloaded])

    return (
        <div>
            {guidesResponse && (
                <ul className={styles.guidesWrapper}>
                    <InfiniteScroll
                        dataLength={guidesResponse.guides.length}
                        next={loadMoreGuides}
                        hasMore={true}>
                            {guidesResponse.guides.length > 0 ?guidesResponse.guides.map(
                                guide => (
                                    <li 
                                        key={`guide-${guide._id}`}
                                        className={styles.guide}>
                                            {onSelect && (
                                                <div className="ml-1 mr-1">
                                                    <Checkbox
                                                        onChange={(value) => onSelect(guide._id)}
                                                    />
                                                </div>
                                            )}
                                        <div 
                                            className={styles.guideItem} 
                                            // onClick={() => history.push(`/guides/${guide._id}?source=${source}`)}
                                            onClick={() => window.open(`${RADMIN_URL}/guide/${guide._id}`)}
                                            >
                                            <div className={styles.guideCover}>
                                                {guide.cover && (
                                                    <img 
                                                        src={CLOUDINARY_URL + '/w_250,f_auto/' + guide.cover.standard_resolution.url}
                                                        onError={onImageError}
                                                        alt={guide.name + 'cover'}
                                                    />
                                                )}
                                            </div>
                                            <div className={styles.guideBody}>
                                                <h3>{guide.name}</h3>
                                                <p>
                                                    {guide.updated_date && (
                                                        <span >
                                                            Modified on 
                                                            <span className="mr-1 ml-1">{moment(guide.updated_date).format('MMM DD')}</span>
                                                        </span>
                                                    )}
                                                    {(user.isAdmin || user?.permissions?.editGuide) && <a 
                                                        target="_blank"
                                                        className={styles.editButton} 
                                                        onClick={((e) => e.stopPropagation())}
                                                        href={`${RADMIN_URL}/edit/guide/${guide._id}?org_token=${token}&redirect_url=${window.location.href}`}
                                                        >
                                                        Edit
                                                    </a>}
                                                    {(user.isAdmin || user?.permissions?.manageGuideActivities) && <a 
                                                        target="_blank"
                                                        href={`${RADMIN_URL}/guide/${guide._id}/activities?org_token=${localStorage.getItem('token')}&redirect_url=${window.location.href}&explorer_id=${guide._explorer._id}`}
                                                        className={styles.editButton} 
                                                        onClick={((e) => {
                                                            e.stopPropagation()
                                                        })}
                                                        >
                                                        Manage Guide Activities
                                                    </a>}
                                                    {(user.isAdmin || user?.permissions?.deleteGuide) && <span 
                                                        target="_blank"
                                                        // href={`${RADMIN_URL}/guide/${guide._id}/activities?org_token=${localStorage.getItem('token')}&redirect_url=${window.location.href}&explorer_id=${guide._explorer._id}`}
                                                        className={styles.deleteButton} 
                                                        onClick={((e) => {
                                                            e.stopPropagation()
                                                            onDeleteGuide(guide._id)
                                                        })}
                                                        >
                                                        Delete
                                                    </span>}
                                                    {/* <a 
                                                        target="_blank"
                                                        className={styles.editButton} 
                                                        href={`${RADMIN_URL}/guide/${guide._id}`}
                                                        >
                                                        Preview
                                                    </a> */}
                                                </p>
                                                {guide._explorer && (
                                                    <p className="color-black">{'By '}
                                                        <a 
                                                            target="_blank" 
                                                            className={styles.explorerLink} 
                                                            onClick={((e) => {
                                                                e.stopPropagation()
                                                            })}
                                                            href={guide._explorer.email 
                                                                ? `${RADMIN_URL}/e/${guide._explorer.email}`
                                                                : `${RADMIN_URL}/e/${guide._explorer._id}`
                                                            }>
                                                            {guide._explorer.full_name}
                                                        </a>
                                                    </p>
                                                )}
                                                <p className={styles.description}>{guide.description}</p>
                                            </div>
                                        </div>
                                    </li>
                                )
                            ) : <EmptyResult/>}
                    </InfiniteScroll>
                </ul>
            )}

            {guidesLoading && (
                <Loader/>
            )}
        </div>
    )
}

export default GuidesList