import React from 'react'
import { gql } from 'apollo-boost'
import useImageUploader from '../../hooks/useImageUpload'
import { Button, Icon } from '../Theme'
import Loader from '../Loader'
import styles from './index.module.css'


const ImageUpload = ({
    btnValue = 'Select File...',
    value,
    name,
    onValueChange,
}) => {
    const {uploader, loading} = useImageUploader();


    const upload = (e) => {
        uploader(e.target.files[0])
        .then(response => onValueChange(response.Location))
        .catch(console.error)
    }

    const remove = () => {
        onValueChange(null)
    }

    return (
        <div>
            {name && <h6>{name}</h6>}
            <div className={styles.imageBox}>
                {value && (
                    <img 
                        src={value}
                        alt={name}
                    />
                )}
                {loading && (
                    <Loader/>
                )}
                <div style={{height: '40px'}}>
                    <label className={styles.upload}>
                        <span>{btnValue}.</span>
                        <input 
                            type="file"
                            onChange={upload}
                        />
                    </label>
                    {value && <Button 
                        onClick={remove}
                        primary>
                            <Icon 
                                icon="delete"
                                size={2}
                            />
                    </Button>}
                </div>
            </div>
        </div>
    )
}

export default ImageUpload