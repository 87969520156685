import React from 'react'
import classNames from 'classnames';
import styles from './index.module.css'

const Loader = ({ className }) => {
    return (
        <div className={classNames({[styles.loader]: true, [className]: true})}>
            <div>
                <svg 
                    viewBox="0 0 50 50">
                        <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5"/>
                </svg>
            </div>
        </div>
    )
}

export default Loader