import React from 'react'
import styles from './index.module.css'
import MediaItem from '../PedMediaItem'
import TextField from '../PedTextField'

const Step1Form = ({
    pedSettings,
    onChange,
}) => {
    return (
        <div className={styles.container}>
        <table border-collapse="true" className={styles.table}>
            <thead>
                <tr>
                    <th>Field</th>
                    <th>Content</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <TextField
                        onChange={value => {
                            onChange({'question1': value})}
                        }
                        label='Question'
                        value={pedSettings.question1 }
                />
                <MediaItem 
                    resize={true}
                    name='Shopping'
                    url={pedSettings.shopping}
                    setUrl={(value => onChange({shopping: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Nature'
                    url={pedSettings.nature}
                    setUrl={(value => onChange({nature: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Cultural'
                    url={pedSettings.cultural}
                    setUrl={(value => onChange({cultural: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Arts'
                    url={pedSettings.arts}
                    setUrl={(value => onChange({arts: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Food'
                    url={pedSettings.food}
                    setUrl={(value => onChange({food: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Relaxing'
                    url={pedSettings.relaxing}
                    setUrl={(value => onChange({relaxing: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Thrills'
                    url={pedSettings.thrills}
                    setUrl={(value => onChange({thrills: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Historical'
                    url={pedSettings.historical}
                    setUrl={(value => onChange({historical: value}))}
                />
            </tbody>
        </table>
        {/* <div className={styles.form}>    
            <div className={styles.name}>
                <h4>Shopping</h4>
            </div>
            <div className={styles.uploader}>
                <ImageUpload 
                            value={pedSettings.shopping}
                            onValueChange={(path) => onChange({ 'shopping': path})                            }
                        />
            </div>
        </div> 
        <div className={styles.form}>    
            <div className={styles.name}>
                <h4>Nature</h4>
            </div>
            <div className={styles.uploader}>
                <ImageUpload 
                            value={pedSettings.nature}
                            onValueChange={(path) => onChange({ 'nature': path})}
                        />
            </div>
        </div> 
        <div className={styles.form}>    
            <div className={styles.name}>
                <h4>Cultular</h4>
            </div>
            <div className={styles.uploader}>
                <ImageUpload 
                            value={pedSettings.cultural}
                            onValueChange={(path) => onChange({ 'cultural': path})}
                        />
            </div>
        </div> 
        <div className={styles.form}>    
            <div className={styles.name}>
                <h4>Arts</h4>
            </div>
            <div className={styles.uploader}>
                <ImageUpload 
                            value={pedSettings.arts}
                            onValueChange={(path) => onChange({ 'arts': path})}
                        />
            </div>
        </div>
        <div className={styles.form}>    
            <div className={styles.name}>
                <h4>Food</h4>
            </div>
            <div className={styles.uploader}>
                <ImageUpload 
                            value={pedSettings.food}
                            onValueChange={(path) => onChange({ 'food': path})}
                        />
            </div>
        </div>
        <div className={styles.form}>    
            <div className={styles.name}>
                <h4>Relaxing</h4>
            </div>
            <div className={styles.uploader}>
                <ImageUpload 
                            value={pedSettings.relaxing}
                            onValueChange={(path) => onChange({ 'relaxing': path})}
                        />
            </div>
        </div>
        <div className={styles.form}>    
            <div className={styles.name}>
                <h4>Thrills</h4>
            </div>
            <div className={styles.uploader}>
                <ImageUpload 
                            value={pedSettings.thrills}
                            onValueChange={(path) => onChange({ 'thrills': path})}
                        />
            </div>
        </div>
        <div className={styles.form}>    
            <div className={styles.name}>
                <h4>Historical</h4>
            </div>
            <div className={styles.uploader}>
                <ImageUpload 
                            value={pedSettings.historical}
                            onValueChange={(path) => onChange({ 'historical': path})}
                        />
            </div>
        </div>  */}
        </div>
    )
}

export default Step1Form