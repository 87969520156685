import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './index.module.css'

const BreadCump = ({ activeName, breadcumps = [] }) => {
    const history = useHistory()

    return (
            <>
                {
                    breadcumps.map((breadcump, index) => (
                        <Fragment key={breadcump}>
                            <span className={styles.breadcump} onClick={() => history.go(index - breadcumps.length)}>
                                {breadcump}
                            </span>
                            <span className="ml-1 mr-1">/</span>
                        </Fragment>
                    ))
                }
                {activeName}
            </> 
    )
}

export default BreadCump