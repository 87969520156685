import React, {useState, useReducer, useMemo, useEffect} from 'react'
import { GET_POD_SETTINGS, UPDATE_POD_SETTINGS, DEFAULT_PED_SETTINGS } from './constants'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Modal, Button, Tabs } from '../Theme'
import WelcomeForm from './PedSettingsForms/WelcomeForm'
import Step1Form from './PedSettingsForms/Step1Form'
import TravelingForForm from "./PedSettingsForms/TravelingForForm";
import Step2Form from "./PedSettingsForms/Step2Form";
import Step3Form from "./PedSettingsForms/Step3Form";
import ResultScreenForm from "./PedSettingsForms/ResultScreenForm";
import styles from './index.module.css'
import Loader from "../Loader";
import { message } from 'antd'


const PedSettingsModal = (props) => {
    const { loading, data } = useQuery(GET_POD_SETTINGS, {
        variables: {
            cityCollectionId: props.cityCollectionId,
        },
        fetchPolicy: "network-only"
    })
    const [updatePedSettings, { loading: updatePedSettingsloading }] = useMutation(UPDATE_POD_SETTINGS)
    const [tab, setTab] = useState('Welcome');
    const tabs = ['Welcome','Traveling For', 'Step 1', 'Step 2', 'Step 3', 'Result Screen']
    const [pedSettings, setSettings] = useReducer(
        (state, value) => ({...state, ...value}),
        {}
    )
    const next = () => {
        const i = tabs.indexOf(tab)

        return setTab((tabs[i+1]) ? tabs[i+1] : tabs[0])
    }
    const prev = () => {
        const i = tabs.indexOf(tab)

        return setTab((tabs[i-1]) ? tabs[i-1] : tabs[tabs.length -1])
    }

    const Form = useMemo(() => {
        let Form
        switch (tab) {
            case 'Welcome':
                Form = WelcomeForm
                break;
            case 'Traveling For':
                Form = TravelingForForm
            break;
            case 'Step 1':
                Form = Step1Form
            break;
            case 'Step 2':
                Form = Step2Form
            break;
            case 'Step 3':
                Form = Step3Form
            break;
            case 'Result Screen':
                Form = ResultScreenForm
            break;
        
            default:
                break;
        }

        return (
            !loading 
            ? <Form pedSettings={pedSettings} onChange={setSettings}/> 
            : <Loader />
        )
    }, [tab, loading, pedSettings]) 

    useEffect(() => {

        data && data.getPedSettings 
            ? setSettings({...data.getPedSettings, ...data.getPedSettings.keywords})
            : setSettings({...DEFAULT_PED_SETTINGS, ...DEFAULT_PED_SETTINGS.keywords})

    }, [data])

    const submit = () => {
        const gqlVariable = {
            cityCollectionId: props.cityCollectionId,
            welcomeText1: pedSettings.welcomeText1,
            welcomeText2: pedSettings.welcomeText2,
            welcomeText3: pedSettings.welcomeText3,
            welcomeImage: pedSettings.welcomeImage,
            welcomeButton: pedSettings.welcomeButton,
            question1: pedSettings.question1,
            question2: pedSettings.question2,
            question3: pedSettings.question3,
            travelingForText: pedSettings.travelingForText,
            travelingForImage: pedSettings.travelingForImage,
            keywords: {
                shopping: pedSettings.shopping,
                nature: pedSettings.nature,
                cultural: pedSettings.cultural,
                arts: pedSettings.arts,
                food: pedSettings.food,
                relaxing: pedSettings.relaxing,
                thrills: pedSettings.thrills,
                historical: pedSettings.historical,
                souvenirs: pedSettings.souvenirs,
                romantic: pedSettings.romantic,
                polynesia: pedSettings.polynesia,
                museum: pedSettings.museum,
                beauty: pedSettings.beauty,
                bars: pedSettings.bars,
                games: pedSettings.games,
                thriftstores: pedSettings.thriftstores,
                dining: pedSettings.dining,
            },
            airlineLogo: pedSettings.airlineLogo,
            resultMessage: pedSettings.resultMessage,
            loadingText: pedSettings.loadingText,
            resultText: pedSettings.resultText,
        }
        updatePedSettings({variables: gqlVariable}).then(() => {
            message.success("Ped Settings successfully updated")
            props.onEnd()
            props.onClose()
        })
    }

    return (
        <Modal
            title="PED Settings"
            showClose={true}
            wrapBody={true}
            onClose={() => props.onClose()}
            maxWidth={1200}
            height={"70vh"}
        >
            <div>
                <div className={styles.tabsContainer}>
                    <Tabs
                        activeTab={tab}
                        tabs={tabs}
                        onChange={setTab}
                    />
                </div>
                <div className={styles.formSection}>
                {Form}
                <div>
                    {/* <p className={styles.unpublishingDesc}>
                        Unpublish Ped Website 
                    </p> */}
                    {props.unpublishButton && <Button
                        color="red" 
                        style={{
                            alignSelf: 'flex-end',
                            width: 180
                        }}
                        onClick={() => props.unpublishButton()}
                        primary>
                            Unpublish
                    </Button>}
                </div>
                </div>
                <div className={styles.footer}>
                    <Button
                        color="blcak" 
                        style={{width: 180}}
                        onClick={props.onClose}
                        primary>
                            Cancel
                    </Button>

                    <div className={styles.move}>
                        <Button 
                            style={{width: 180}}
                            onClick={prev}
                            >
                                Previous
                        </Button>
                        
                        <Button
                            style={{width: 180}}
                            onClick={next}
                            >
                                Next
                        </Button>
                    </div>
                    
                    <Button 
                        color="blue" 
                        style={{width: 180}}
                        onClick={submit}
                        loading={updatePedSettingsloading}
                        primary>
                            Save
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default PedSettingsModal