import React, { useReducer, useState, useRef, useEffect, useMemo } from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { TOGGLE_ITEMS_IN_CITY_COLLECTION } from '../../mutations'
import { useForceUpdate } from '../../hooks'
import { Button, Icon, Input, Switch, Tabs } from '../Theme'
import Loader from '../Loader'
import CityCountryFilters from '../CityCountryFilters'
import CityCollectionCount from '../CityCollectionCount'
import Page from '../Page'
import ExperiencesList from '../ExperiencesList'
import ExplorersList from '../ExplorersList'
import CitiesList from '../CitiesList'
import GuidesList from '../GuidesList'
import VideosList from '../VideosList'
import BreadCumps from '../BreadCrumps'
import PedSettingsModal from '../PedSettingsModal'
import WebsiteSettingsModal from '../WebsiteSettingsModal'
import TokensModal from './TokensModal'
import { PED_URL, WEBSITE_URL } from '../../constants'
import styles from './index.module.css'

const GET_CITY_COLLECTION = gql`
    query getCityCollection ($id: ID) {
        cityCollection (id: $id) {
            _id,
            name,
            slug,
            _org {
                orgalias,
            },
            website_published,
            pedPublished,
            live_accesstoken,
            test_accesstoken,
        }
    }
`

const PUBLISH_PED_WEBSITE = gql`
    mutation publishPed ($id: ID!) {
        publishPed (id: $id) {
            _id,
            name,
            slug,
            _org {
                orgalias,
            },
            pedPublished,
            live_accesstoken,
            test_accesstoken,
        }
    }
`
const UNPUBLISH_PED_WEBSITE = gql`
    mutation unpublishPed ($id: ID!) {
        unpublishPed (id: $id) {
            _id,
            name,
            slug,
            _org {
                orgalias,
            },
            pedPublished,
            live_accesstoken,
            test_accesstoken,
        }
    }
`

const PUBLISH_API = gql`
    mutation publishApi ($id: ID!) {
        publishApi (id: $id) {
            _id,
            live_accesstoken,
            test_accesstoken,
        }
    }
`
const UNPUBLISH_API = gql`
    mutation unpublishApi ($id: ID!) {
        unpublishApi (id: $id) {
            _id,
            live_accesstoken,
            test_accesstoken,
        }
    }
`

// TODO hardcoded pac-ped url line 240
const CityCollection = () => {
    const [tab, setTab] = useState('Experiences')
    const [pedSettingsModal, togglePedSettingsModal] = useState(false)
    const [websiteSettingsModal, setWebsiteSettingsModal] = useState(false)
    const [tokensModal, toggleTokensModal] = useState(false)
    const selected = useRef(new Set())
    const [selectedCount, setSelectedCount] = useState(0)
    const { id } = useParams()
    const [reloaded, reload] = useForceUpdate()
    const [toggleItems, { loading: deleting }] = useMutation(TOGGLE_ITEMS_IN_CITY_COLLECTION)
    const [publishPed, { loading: publishing }] = useMutation(PUBLISH_PED_WEBSITE)
    const [unpublishPed, { loading: unpublishing }] = useMutation(UNPUBLISH_PED_WEBSITE)
    const [publishApi, {loading: publishingApi}] =useMutation(PUBLISH_API)
    const [unpublishApi, {loading: unpublishingApi}] =useMutation(UNPUBLISH_API)

    const {
        data: cityCollectionResponse,
        loading: loadingCityCollection,
    } = useQuery(GET_CITY_COLLECTION, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id
        }
    })

    const [filters, setFilter] = useReducer(
        (state, value) => ({ ...state, ...value }),
        {
            name: '',
            sortBy: 'updated_date',
            country: undefined,
            city: undefined,
        }
    )

    const onSelect = (id) => {
        if (selected.current.has(id)) {
            selected.current.delete(id)
        } else {
            selected.current.add(id)
        }

        setSelectedCount(
            [...selected.current].length
        )
    }

    const deleteItems = () => {
        const model = (() => {
            if (tab === 'Experiences') return 'Activity'
            if (tab === 'Explorers') return 'Explorer'
            if (tab === 'Cities') return 'City'
            if (tab === 'Guides') return 'Guide'
            // if (tab === 'Videos') return 'Video'
        })()
        // return console
        toggleItems({
            variables: {
                id,
                ids: [...selected.current].map((item => item._id ? item._id : item)),
                model,
                action: 'remove',
            }
        }).then(() => {
            reload()
            selected.current = new Set()
            setSelectedCount(0)
        })
        .catch(console.error)
    }

    const list = useMemo(() => {
        if (!cityCollectionResponse || !reloaded) {
            return
        }

        let List
        if (tab === 'Experiences') List = ExperiencesList
        if (tab === 'Explorers') List = ExplorersList
        if (tab === 'Cities') List = CitiesList
        if (tab === 'Guides') List = GuidesList
        // if (tab === 'Videos') List = VideosList

        return (
            <List
                source={`Dataset,${cityCollectionResponse.cityCollection.name}`}
                onSelect={onSelect}
                filters={{
                    ...filters,
                    dataset: cityCollectionResponse.cityCollection.slug,
                }}
            />
        )
    }, [filters, cityCollectionResponse, reloaded, tab])

    useEffect(() => {
        selected.current = new Set()
    }, [tab])

    if (loadingCityCollection) {
        return <Loader/>
    }

    const { cityCollection } = cityCollectionResponse
    const dataset = cityCollection.slug

    return (
        <Page
            customTitle={<>
                <Icon 
                    icon="datasets"
                    size={2.5}/>
                <h1 className={styles.title}>
                    <BreadCumps breadcumps={['Datasets']} activeName={cityCollection.name}/>
                </h1>
            </>}
            buttons={[
                <div className={styles.tokens}>
                    <Button
                        key="publish-button"
                        onClick={() => setWebsiteSettingsModal(true)}
                        primary>
                            <Icon
                                icon="publish"
                                size={2}
                            />
                            {cityCollection.website_published ? 'Hotel Website Settings' : 'Publish Hotel Website'}
                    </Button>
                    {cityCollection.website_published && <a target="_blank" href={`${WEBSITE_URL}/${cityCollection.slug}`} className={styles.pedUrl}>
                    <div>   
                        {`${WEBSITE_URL}/${cityCollection.slug}`}
                    </div>
                    </a>}
                </div>,

                <div className={styles.tokens}>
                    <Button
                        key="publish-button"
                        onClick={ () => togglePedSettingsModal(true)}
                        loading={publishing || unpublishing}
                        primary>
                            <Icon
                                icon="publish"
                                size={2}
                            />
                            {cityCollection.pedPublished ? 'PED Settings' : 'Publish PED Website'}
                    </Button>
                    {cityCollection.pedPublished && <a target="_blank" href={`${PED_URL}/${cityCollection.slug}`} className={styles.pedUrl}>
                    <div>   
                        {`${PED_URL}/${cityCollection.slug}`}
                    </div>
                    </a>}
                </div>,
                <div className={styles.tokens}>
                    <Button
                        key="publish-button"
                        loading={publishingApi}
                        onClick={() => 
                            cityCollection.live_accesstoken && cityCollection.test_accesstoken
                                ? toggleTokensModal(true)
                                : publishApi({
                                        variables :{ id: cityCollection._id }
                                    }).then(() => toggleTokensModal(true))
                        }
                        primary>
                            <Icon
                                icon="publish"
                                size={2}
                            />
                            {
                            (cityCollection.live_accesstoken && cityCollection.test_accesstoken)
                                ? 'API Settings' 
                                : 'Publish Api'
                            }
                    </Button>
                </div>,
                <Button
                key="delete-button"
                color="red"
                onClick={deleteItems}
                disabled={selectedCount === 0 || undefined}
                loading={deleting}
                primary>
                    <Icon
                        icon="delete"
                        size={2}
                    />
                    Delete {selectedCount ? selectedCount : ''} {tab}
            </Button>,
                
            ]}>
                <Input
                    placeholder="Search By Keyword"
                    icon="search"
                    onValueChange={value => setFilter({'name': value })}
                />
                <div>
                    <span className="mr-2">Sort By</span>
                    <Switch
                        value={filters.sortBy}
                        onChange={value => setFilter({ 'sortBy': value })}
                        values={[
                            ['Modified', 'updated_date'],
                            ['Name', 'name'],
                            ['Published', 'published'],
                            ['Custom', 'custom'],
                        ]}
                    />
                </div>

                <CityCountryFilters
                    disabled={{ city: tab === 'Cities' }}
                    cityValue={filters.city}
                    countryValue={filters.country}
                    onCityChange={setFilter}
                    onCountryChange={setFilter}
                    filters={{ dataset }}
                />

                <Tabs
                    activeTab={tab}
                    tabs={['Experiences', 'Explorers', 'Cities', 'Guides',]}
                    onChange={setTab}
                />
                <CityCollectionCount reload={reloaded} slug={cityCollection.slug} type={tab} />
                {list}
                {
                    pedSettingsModal && <PedSettingsModal
                        onClose={() => togglePedSettingsModal(false)}
                        cityCollectionId={id}
                        unpublishButton={cityCollection.pedPublished 
                            ? () => unpublishPed({
                                variables: {id: cityCollection._id}
                            })
                            : false
                        }
                        onEnd={cityCollection.pedPublished 
                            ? () => {}
                            : () => publishPed({
                                variables : {id: cityCollection._id}
                            })
                        }
                    />
                }
                {
                    websiteSettingsModal && <WebsiteSettingsModal 
                        cityCollection={cityCollectionResponse?.cityCollection || {}}
                        published={cityCollection.website_published}
                        onClose={() => setWebsiteSettingsModal(false)}
                    />
                }
                {
                    tokensModal && <TokensModal 
                        liveToken={cityCollection.live_accesstoken}
                        testToken={cityCollection.test_accesstoken}
                        onUnpublish={() => unpublishApi({
                            variables :{ id: cityCollection._id }
                        })}
                        onClose={() => toggleTokensModal(false)}
                    />
                }
        </Page>
    )
}

export default CityCollection