import React, { useReducer, useState } from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { useUser } from '../../hocs/auth'
import { useParams, Link } from 'react-router-dom'
import { Icon, Input, Switch } from '../Theme'
import Loader from '../Loader'
import CityCountryFilters from '../CityCountryFilters'
import Page from '../Page'
import ExperiencesList from '../ExperiencesList'
import { CLOUDINARY_URL, RADMIN_URL} from '../../constants'
import styles from './index.module.css'

const GET_EXPLORER = gql`
    query getExplorer (
        $id: ID,
    ) {
        explorer (
            id: $id,
        ) {
            _id,
            profile_picture,
            full_name,
            username,
            bio,
            descriptor,
        }
    }
`

const Explorer = () => {
    const { id } = useParams()
    const { data: explorerResponse, loading: loadingExplorer } = useQuery(GET_EXPLORER, {
        variables: {
            id
        }
    })
    const [modal, setModal] = useState(false)
    const { user } = useUser()

    const [filters, setFilter] = useReducer(
        (state, value) => ({ ...state, ...value }),
        {
            name: '',
            sortBy: 'modified',
            city: undefined,
            country: undefined,
        }
    )

    if (loadingExplorer) {
        return <Loader />
    }

    const { explorer } = explorerResponse

    return (
        <Page
            customTitle={<>
                <Icon
                    icon="Explorers"
                    size={2.5} />
                <h1 className={styles.title}>
                    <Link to="/explorers">
                        Explorers
                        <span className="ml-1 mr-1">/</span>
                    </Link>
                    {explorer.full_name}
                </h1>
            </>}
            help={{
                title: 'How Explorers Work',
                description: 'This section allows you to add Explorers to your dataset.',
                sections: [
                    [
                        'Organize:',
                        <p>Search Explorers using the search box or the drop down.  When you locate the Explorers you want in your Dataset, click on the checkbox to the left of their photo to include them.</p>
                    ],
                    [
                        'Implement:',
                        <p>When you feel good about your list, click ADD ITEMS TO DATASET+ below to add all checked items, then name your Dataset. If you have any questions, <a href="mailto:masha@rad.travel">email us</a>. We are here for you.</p>
                    ]
                ]
            }}>
                <div className={styles.explorer}>
                    <img
                        src={CLOUDINARY_URL + '/w_200,f_auto/' + explorer.profile_picture}
                        onError={(e) => e.target.src = "https://res.cloudinary.com/dbur3sjwh/image/upload/v1590513469/placeholder-profile_omjhxm.jpg" }
                        alt="explorer profile"
                    />
                    <div>
                        {explorer.username && (
                            <a href={`https://instagram.com/${explorer.username}`}>
                                @{explorer.username}
                            </a>
                        )}
                        
                        {(user.isAdmin || user?.permissions?.editExplorer) && <a
                            target="_blank"
                            style={{marginLeft: '1em', textDecoration: 'underline'}}
                            href={`${RADMIN_URL}/e/${explorer._id}?org_token=${localStorage.getItem('token')}&redirect_url=${window.location.href}&editable=true`}
                            onClick={(e) => e.stopPropagation()}>
                            Edit
                        </a>}
                        <a
                            style={{marginLeft: '1em', textDecoration: 'underline'}}
                            target="_blank"
                            href={`${RADMIN_URL}/e/${explorer.email}`}
                            onClick={(e) => e.stopPropagation()}>
                            Preview
                        </a>
                        {explorer.bio && (
                            <p>{explorer.bio}</p>
                        )}
                        {explorer.descriptor && (
                            <p>{explorer.descriptor}</p>
                        )}
                    </div>
                </div>
                <Input
                    placeholder="Search By Keyword"
                    icon="search"
                    onValueChange={value => setFilter({ 'name': value })}
                />
                <div>
                    <span className="mr-2">Sort By</span>
                    <Switch
                        value={filters.sortBy}
                        onChange={value => setFilter({ 'sortBy': value })}
                        values={[
                            ['Modified', 'modified'],
                            ['Name', 'name'],
                        ]}
                    />
                </div>

                <CityCountryFilters
                    cityValue={filters.city}
                    countryValue={filters.country}
                    onCityChange={setFilter}
                    onCountryChange={setFilter}
                    filters={{ explorer: id }}
                />

                <ExperiencesList
                    filters={{
                        ...filters,
                        explorer: id,
                    }}
                />
        </Page>
    )
}

export default Explorer