import React, { useState, useRef, useReducer } from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { TOGGLE_ITEMS_IN_CITY_COLLECTION } from '../../mutations'
import { useForceUpdate } from '../../hooks'
import { Button, Icon, Input, Switch } from '../Theme'
import Page from '../Page'
import VideosList from '../VideosList'
import VideoModal from './VideoModal'
import SelectCityCollectionModal from '../SelectCityCollectionModal'
import styles from './index.module.css'
import LoadingContainer from '../LoadingContainer'


const GET_CITIES = gql`
    query getVideoCities {
        videoCities {
            _id,
            name,
            country
        }
    }
`

const GET_VIDEOS_COUNT = gql`
    query getVideosCount (
        $name: String,
        $city: String,
        $country: String,
    ) {
        videosCount (
            name: $name,
            city: $city,
            country: $country,
        )
    }
`

const DELETE_VIDEO = gql`
    mutation deleteVideo ($id: ID) {
        deleteVideo (id: $id) {
            success
        }
    }
`

const Videos = () => {
    const [videoModal, toggleVideoModal] = useState(false)
    const [cities, setCities] = useState([])
    const [countries, setCountries] = useState([])
    const cityCountries = useRef()
    const [selectCollection, toggleSelectCollection] = useState(false)
    const [deleteVideo, { loading: deleting }] = useMutation(DELETE_VIDEO)
    const [toggleItems, { loading: adding }] = useMutation(TOGGLE_ITEMS_IN_CITY_COLLECTION)
    const [selectedCount, setSelectedCount] = useState(0)
    const selected = useRef(new Set())
    const [reloaded, reload] = useForceUpdate()

    const [filters, setFilters] = useReducer(
        (state, [field, value]) => ({...state, [field]: value}),
        {
            name: '',
            sortBy: '',
        }
    )

    const {
        data: videosCountResponse,
        refetch: recountVideos, 
        loading
    } = useQuery(GET_VIDEOS_COUNT)
    
    useQuery(GET_CITIES, {
        onCompleted: (data) => {
            cityCountries.current = {}
            setCities([
                ...new Set(
                    data.cities.map(
                        city => {
                            cityCountries.current[city.name] = city.country
                            return city.name
                        }
                    )
                )
            ])

            const countries = [
                ...new Set(
                    data.cities.map(
                        city => city.country
                    )
                )
            ]

            countries.sort(
                (a, b) => {
                    if(a < b) return -1
                    if(a > b) return 1
                    return 0
                }
            )

            setCountries(countries)
        }
    })

    const filter = (key, value) => {
        setFilters([key, value])
        recountVideos({...filters, [key]: value})
            .catch(console.error)
    }

    const onSelect = (id) => {
        if (selected.current.has(id)) {
            selected.current.delete(id)
        } else {
            selected.current.add(id)
        }

        setSelectedCount(
            [...selected.current].length
        )
    }

    const add = (collections) => {
        Promise.all(
            collections.map(
                id => toggleItems({
                    variables: {
                        id,
                        ids: [...selected.current],
                        model: 'Video',
                    }
                })
            )
        ).then(() => {
            reload()
            selected.current = new Set()
            setSelectedCount(0)
        })
        .catch(console.error)
    }

    const deleteVideos = () => {
        Promise.all(
            [...selected.current].map(
                id => deleteVideo({ variables: { id } })
            )
        ).then(
            () => {
                reload()
                selected.current = new Set()
                setSelectedCount(0)
            }
        ).catch(console.error)
    }

    return (
        <Page
            title="Videos"
            icon="datasets"
            help={{
                title: 'How Videos Work',
                description: 'This section allows you to add Videos to your dataset.',
                sections: [
                    [
                        'Curate:',
                        <p>Search Videos with the search box or sort by Name/Date/Filter.  When you locate the Videos you want in your Dataset, click the checkbox to include them.</p>
                    ],
                    [
                        'Implement:',
                        <p>When you feel good about your list, click ADD ITEMS TO DATASET+ below to add all checked items, then name your Dataset. If you have any questions, <a href="mailto:masha@rad.travel">email us</a>. We are here for you.</p>
                    ]
                ]
            }}
            buttons={[
                <Button 
                    key="add-video"
                    color="green"
                    onClick={() => toggleVideoModal(true)}
                    primary>
                        <Icon 
                            icon="add"
                            size={2}
                        />
                        Add New Video
                </Button>,
                <Button 
                    key="add-to-dataset"
                    onClick={() => toggleSelectCollection(true)}
                    disabled={selectedCount === 0 || undefined}
                    loading={adding}
                    primary>
                        <Icon 
                            icon="add"
                            size={2}
                        />
                        Add {selectedCount ? selectedCount : ''} Videos To Dataset
                </Button>,
                <Button
                    key="delete-videos"
                    color="red"
                    onClick={deleteVideos}
                    disabled={selectedCount === 0 || undefined}
                    loading={deleting}
                    primary>
                        <Icon 
                            icon="delete"
                            size={2}
                        />
                        Delete {selectedCount ? selectedCount : ''} Videos
                </Button>
            ]}>
                <div>
                    <Input
                        placeholder="Search by name"
                        icon="search"
                        onValueChange={value => filter('name', value)}
                    />

                    <div>
                        <span className="mr-2">Sort By</span>
                        <Switch
                            value="name"
                            onChange={value => filter('sortBy', value)}
                            values={[
                                ['Name', 'name'],
                                ['Date', 'updated_date'],
                            ]}
                        />
                    </div>
                    
                    {/*  */}
                </div>

                <div className={styles.refreshBar}>
                    {videosCountResponse && (
                        <h3>{videosCountResponse.videosCount} Videos</h3>
                    )}
                </div>

                <LoadingContainer loading={loading}>
                    {reloaded && (
                        <VideosList
                            filters={filters}
                            onSelect={onSelect}
                        />
                    )}
                </LoadingContainer>

                {videoModal && (
                    <VideoModal
                        onClose={() => toggleVideoModal(false)}
                        reload={reload}
                    />
                )}

                {selectCollection && (
                    <SelectCityCollectionModal
                        onAdd={add}
                        onClose={() => toggleSelectCollection(false)}
                    />
                )}
        </Page>
    )
}

export default Videos