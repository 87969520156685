import React, { useState, useEffect } from 'react'
import Loader from '../Loader'


const LadingContainer = ({loading: childLoading, children }) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => setLoading(childLoading), [childLoading])

    return  loading ? <Loader /> : children
                 
}

export default LadingContainer