import React from 'react'
import {Input, Checkbox} from '../../Theme'
import ImageUploader from '../../PedSettingsModal/PedSettingsForms/PedMediaItem/PedImageUploader'
import styles from './index.module.css'


const WebSettingsForm = ({
    settings,
    onChange
}) => {
    return (
            <div className={styles.listWrapper}>
                <div className={styles.formControl}>
                        <label>
                            Image Cover
                        </label>
                    <div 
                        className={styles.inputWrapper}>
                            <ImageUploader  
                                value={settings.imageCover}
                                onValueChange={src => onChange({
                                    ...settings,
                                    imageCover: src
                                })}
                            />
                    </div>
                </div>
                <Input
                    label="Video Cover Url"
                    value={settings.videoCoverUrl}
                    required="Name required"
                    onChange={e => onChange({
                        ...settings,
                        videoCoverUrl: e.target.value
                    })}
                />
                <Checkbox
                    // key={i + 'key'} 
                    name={'showGuide'}
                    value={settings.showGuide}
                    onChange={data => onChange({
                        ...settings,
                        showGuide: data
                    })}>   
                    <span className={styles.name}>
                        Show Guides
                    </span>
                </Checkbox>
                <Checkbox
                    // key={i + 'key'} 
                    name={'showActivities'}
                    value={settings.showActivities}
                    onChange={data => onChange({
                        ...settings,
                        showActivities: data
                    })}>   
                    <span className={styles.name}>
                        Show Activities
                    </span>
                </Checkbox>
                <Checkbox
                    // key={i + 'key'} 
                    name={'showCategories'}
                    value={settings.showCategories}
                    onChange={data => onChange({
                        ...settings,
                        showCategories: data
                    })}>   
                    <span className={styles.name}>
                        Show Categories
                    </span>
                </Checkbox>
                <Checkbox
                    // key={i + 'key'} 
                    name={'showExplorers'}
                    value={settings.showExplorers}
                    onChange={data => onChange({
                        ...settings,
                        showExplorers: data
                    })}>   
                    <span className={styles.name}>
                        Show Explorers
                    </span>
                </Checkbox>
            </div>
    )
}

export default WebSettingsForm