import React, { useState, useRef } from 'react'
import { Modal, Button, Input } from '../Theme'
import CityCollectionsList from '../CityCollectionsList'
import styles from './index.module.css'

const SelectCityCollectionModal = ({
    onAdd,
    onClose,
}) => {
    const [name, setName] = useState('')
    const selected = useRef(new Set())

    const onSelect = (id) => {
        if (selected.current.has(id)) {
            selected.current.delete(id)
        } else {
            selected.current.add(id)
        }
    }

    const submit = () => {
        onAdd([...selected.current])
        onClose()
    }

    return (
        <Modal
            title="Add to Dataset"
            showClose={true}
            wrapBody={true}
            onClose={onClose}
        >
            <div>
                <div className={styles.body}>
                    <h2>Select one or more Datasets</h2>

                    <Input 
                        placeholder="City Name"
                        onValueChange={value => setName(value)}
                    />

                    <CityCollectionsList
                        clickable={false}
                        height="40vh"
                        filters={{name}}
                        onSelect={onSelect}
                    />
                </div>
                <div className={styles.footer}>
                    <Button
                        style={{width: 180}}
                        onClick={onClose}>
                            Cancel
                    </Button>
                    <Button 
                        color="blue" 
                        style={{width: 180}}
                        onClick={submit}
                        primary>
                            Add
                    </Button>
                    {/* <Button
                        style={{width: 250}}
                        onClick={onClose}>
                            Create Blank Dataset
                    </Button> */}
                </div>
            </div>
        </Modal>
    )
}

export default SelectCityCollectionModal