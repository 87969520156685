import React, { useState } from 'react'
import styles from './index.module.css'

const Switch = ({
    value,
    values,
    onChange = () => {}
}) => {
    const [selected, select] = useState(value)

    const onSelect = (e) => {
        select(e.target.value)
        onChange(e.target.value)
    }

    return (
        <div className={styles.switch}>
            {values.map(
                ([value, key]) => (
                    <label
                        key={`switch-${key}`}>
                            <input
                                type="radio"
                                name="switch"
                                value={key}
                                checked={selected === key}
                                onChange={onSelect}
                            />
                            <span>{value}</span>
                    </label>
                )
            )}
        </div>
    )
}

export default Switch