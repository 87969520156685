import { gql } from 'apollo-boost'

export const TOGGLE_ITEMS_IN_CITY_COLLECTION = gql`
    mutation toggleItemsInCityCollection (
        $id: ID!,
        $ids: [ID],
        $model: String,
        $action: String,
    ) {
        toggleItemsInCityCollection (
            id: $id,
            ids: $ids,
            model: $model,
            action: $action,
        ) {
            success,
        }
    }
`