export const PERMISSIONS_LIST = {
    'createActivity': 'Create Activity',
    'editActivity': 'Edit Activity',
    'deleteActivity': 'Delete Activity',
    'createGuide': 'Create Guide',
    'editGuide': 'Edit Guide',
    'deleteGuide': 'Delete Guide',
    'manageGuideActivities': 'Menage Guide Activities',
    'editExplorer': 'Edit Explorer',
    'createExplorer': 'Create Explorer'
}
    