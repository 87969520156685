import React from 'react'
import { Route } from 'react-router-dom'
import Login from '../Login'
import ForgotPassword from '../ForgotPassword'
import ResetPassword from '../ResetPassword'
import Footer from '../Footer'
import styles from './index.module.css'

const Auth = () => {
    return (
        <>
            <section className={styles.auth}>
                <div>
                    <div className={styles.logoWrapper}>
                        <img 
                            src="/icons/grey/logo.svg"
                            alt="logo grey"
                        />
                        <span>
                            PARTNERS
                        </span>
                    </div>
                    
                    <Route
                        path="/reset-password/:code"
                        exact>
                            <ResetPassword/>
                    </Route>
                    <Route 
                        path="/forgot-password"
                        exact>
                            <ForgotPassword/>
                    </Route>
                    <Route 
                        path="/"
                        >
                            <Login/>
                    </Route>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Auth