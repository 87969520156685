import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { useEffectSkipFirst } from '../../hooks'
import { Checkbox } from '../Theme'
import Loader from '../Loader'
import styles from './index.module.css'

const GET_CITIES = gql`
    query getCities (
        $name: String,
        $sortBy: String,
        $country: String,
        $page: Int,
        $dataset: String,
    ) {
        cities (
            name: $name,
            sortBy: $sortBy,
            country: $country,
            page: $page,
            dataset: $dataset,
        ) {
            _id,
            name,
            activity_count,
            created_date,
        }
    }
`

const CitiesList = ({
    filters,
    height,
    onSelect,
}) => {
    const [page, setPage] = useState(1)

    const {
        loading: citiesLoading,
        data: citiesResponse,
        fetchMore: fetchMoreCities,
        refetch: loadCities,
    } = useQuery(GET_CITIES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        variables: {
            ...filters,
            page: 1
        },
    })

    const loadMoreCities = () => {
        fetchMoreCities({
            variables: {
                page: page + 1
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev
                }

                setPage(
                    page => page + 1
                )

                return Object.assign(
                    {},
                    prev,
                    {
                        cities: [
                            ...prev.cities,
                            ...fetchMoreResult.cities
                        ]
                    }
                );
            }
        })
    }

    useEffectSkipFirst(() => {
        loadCities(filters)
            .catch(console.error)
    }, [filters])

    return (
        <div>
            {citiesResponse && (
                <ul className={styles.citiesWrapper}>
                    <InfiniteScroll
                        dataLength={citiesResponse.cities.length}
                        next={loadMoreCities}
                        hasMore={true}
                        height={height}>
                            {citiesResponse.cities.map(
                                city => (
                                    <li 
                                        key={`city-${city._id}`}
                                        className={styles.city}>
                                            {onSelect && (
                                                <div className="ml-1 mr-1">
                                                    <Checkbox
                                                        onChange={(value) => onSelect(city._id)}/>
                                                </div>
                                            )}
                                            <div>
                                                <h3>{city.name}</h3>
                                                <p>
                                                    Published on 
                                                    <span className="ml-1 mr-1">
                                                        {moment(city.created_date).format('MMM DD')}
                                                    </span>
                                                    {city.activity_count} {city.activity_count > 1 ? 'Experiences' : 'Experience'}
                                                </p>
                                            </div>
                                    </li>
                                )
                            )}
                    </InfiniteScroll>
                </ul>
            )}

            {citiesLoading && (
                <Loader/>
            )}
        </div>
    )
}

export default CitiesList