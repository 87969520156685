import React, { createContext, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import Loader from '../components/Loader'

const UserContext = createContext()

export const useUser = () => {
    const context = useContext(UserContext)

    if (context === undefined) {
        throw new Error('useUser can only be used inside UserProvider')
    }

    return context
}

const GET_USER = gql`
    {
        me {
            email,
            _id,
            fullName,
            orgname,
            logo,
            avatar,
            orgalias,
            permissions,
        }
    }
`

const LOGIN = gql`
    mutation Login(
        $email: String!,
        $password: String!,
        $remember: Boolean,
    ) {
        login(
            email: $email,
            password: $password,
            remember: $remember,
        ) {
            token,
        }
    }
`

export const withAuth = Component => ({
    ...props
}) => {
    const history = useHistory()

    const {loading, data: userResponse, refetch: getUser } = useQuery(GET_USER, {
        onError: (e) => {
            const error = e.networkError.result?.errors[0]

            if (error && 
                error.message && 
                error.message.includes('session expired')) {
                    localStorage.removeItem('token')
                    history.push('/')
            }
        }
    })
    
    const [login, { data: loginResponse }] = useMutation(LOGIN)

    const logout = () => {
        localStorage.removeItem('token')
        history.push('/')
        getUser()
    }

    useEffect(() => {
        if (!loginResponse) {
            return
        }

        localStorage.setItem('token', loginResponse.login.token)
        getUser()
    }, [getUser, loginResponse])

    useEffect(() => {
        console.log(userResponse,  'loginResponse')

    }, [userResponse])

    if (loading) {
        return (
            <Loader/>
        )
    }

    return (
        <UserContext.Provider value={{
            user: userResponse && userResponse.me && { 
                ...userResponse.me,
                permissions: userResponse.me.permissions ? JSON.parse(userResponse.me.permissions) : {},
                isAdmin: userResponse.me.orgalias === "raleighanddrake" 
            },
            token: loginResponse?.login?.token,
            login,
            logout,
            getUser,
        }}>
            <Component {...props}/>
        </UserContext.Provider>
    )
}

export default withAuth