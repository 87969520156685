import React, { useState, useReducer, useRef } from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useForceUpdate } from '../../hooks'
import { Button, Icon, Input, Switch } from '../Theme'
import Page from '../Page'
import CityCollectionsList from '../CityCollectionsList'
import CreateCityCollectionModal from './CreateCityCollectionModal'
import AddCitiesToCollection from './AddCitiesToCollection'
import styles from './index.module.css'
import { message } from 'antd'
import LoadingContainer from '../LoadingContainer'

const GET_CITY_COLLECTIONS_COUNT = gql`
    query getCityCollectionsCount (
        $name: String,
    ) {
        cityCollectionsCount (
            name: $name,
        )
    }
`

const DELETE_COLLECTION = gql`
    mutation deleteCityCollection (
        $id: ID!,
    ) {
        deleteCityCollection (
            id: $id,
        ) {
            success,
        }
    }
`

const PUBLISH_COLLECTION = gql`
    mutation publishCityCollection (
        $id: ID!,
    ) {
        publishCityCollection (
            id: $id,
        ) {
            success,
        }
    }
`

const UNPUBLISH_COLLECTION = gql`
    mutation unpublishCityCollection (
        $id: ID!,
    ) {
        unpublishCityCollection (
            id: $id,
        ) {
            success,
        }
    }
`

const CityCollections = () => {
    const [create, toggleCreate] = useState(false)
    const [addToCityCollection, toggleAddCities] = useState(undefined)
    const selected = useRef(new Set())
    const [reloaded, reload] = useForceUpdate()
    const [selectedCount, setSelectedCount] = useState(0)
    const [deleteCollection, { loading: deleting }] = useMutation(DELETE_COLLECTION)
    const [publishCollection, { loading: publishing }] = useMutation(PUBLISH_COLLECTION)
    const [unpublishCollection, { loading: unpublishing }] = useMutation(UNPUBLISH_COLLECTION)
    
    const [filters, setFilters] = useReducer(
        (state, [field, value]) => ({...state, [field]: value}),
        {
            name: '',
            sortBy: 'updated_date',
            sortDirection: -1,
        }
    )

    const {
        data: cityCollectionsCountResponse,
        refetch: recountCityCollections,
        loading
    } = useQuery(GET_CITY_COLLECTIONS_COUNT,
        {
            fetchPolicy: 'cache-and-network'
        }
    )

    const filter= (key, value) => {
        setFilters([key, value])
        recountCityCollections({...filters, [key]: value})
            .catch(console.error)
    }

    const onSelect = (id) => {
        if (selected.current.has(id)) {
            selected.current.delete(id)
        } else {
            selected.current.add(id)
        }

        setSelectedCount(
            [...selected.current].length
        )
    }

    const perform = (name) => {
        let fn = () => {}
        if (name === 'delete') fn = deleteCollection
        if (name === 'publish') fn = publishCollection
        if (name === 'unpublish') fn = unpublishCollection

        Promise.all(
            [...selected.current].map(
                id => fn({ variables: { id } })
            )
        ).then(
            () => {
                reload()
                selected.current = new Set()
                setSelectedCount(0)
            }
        ).catch(console.error)
    }

    return (
        <Page
            title="Datasets"
            icon="datasets"
            help={{
                title: 'How Datasets Work',
                description: 'This section allows you to Publish, Create or Delete your Datasets and obtain API test and dev keys for integration.',
                sections: [
                    [
                        'Organize:',
                        <p>Select the Dataset(s) you’d like to publish or delete by clicking on the checkbox. Click on the Dataset to obtain the test or dev access token.</p>
                    ],
                    [
                        'Implement:',
                        <p>Once selected, either Publish or Delete Dataset(s) via the buttons below.</p>
                    ]
                ]
            }}
            buttons={[
                <Button
                    key="create-new-dataset"
                    onClick={() => toggleCreate(true)}
                    primary>
                        <Icon 
                            icon="add"
                            size={2}
                        />
                        Create New Dataset
                </Button>,
                // <Button
                //     key="publish-datasets"
                //     color="green"
                //     disabled={selectedCount === 0 || undefined}
                //     onClick={() => perform('publish')}
                //     loading={publishing}
                //     primary>
                //         <Icon 
                //             icon="publish"
                //             size={2}
                //         />
                //         Publish {selectedCount ? selectedCount : ''} Datasets
                // </Button>,
                // <Button
                //     key="unpublish-datasets"
                //     color="blue"
                //     disabled={selectedCount === 0 || undefined}
                //     onClick={() => perform('unpublish')}
                //     loading={unpublishing}
                //     primary>
                //         <Icon 
                //             icon="publish"
                //             size={2}
                //         />
                //         Unpublish {selectedCount ? selectedCount : ''} Datasets
                // </Button>,
                <Button
                    key="delete-datasets"
                    color="red"
                    onClick={() => perform('delete')}
                    disabled={selectedCount === 0 || undefined}
                    loading={deleting}
                    primary>
                        <Icon 
                            icon="delete"
                            size={2}
                        />
                        Delete {selectedCount ? selectedCount : ''} Datasets
                </Button>
            ]}>
                <div>
                    <Input
                        placeholder="Search Datasets"
                        icon="search"
                        onValueChange={value => filter('name', value)}
                    />

                    <div>
                        <span className="mr-2">Sort By</span>
                        <Switch
                            value="updated_date"
                            onChange={value => filter('sortBy', value)}
                            values={[
                                ['Modified', 'updated_date'],
                                ['Name', 'name'],
                                // ['Published', 'published'],
                                // ['Unpublished', 'unpublished'],
                            ]}
                        />
                    </div>
                </div>

                <div className={styles.refreshBar}>
                    {cityCollectionsCountResponse && (
                        <h3>{cityCollectionsCountResponse.cityCollectionsCount} Datasets</h3>
                    )}
                </div>

                <LoadingContainer loading={loading}>
                    {reloaded && (
                        <CityCollectionsList
                            filters={filters}
                            onSelect={onSelect}
                        />
                    )}
                </LoadingContainer>

                {create && (
                    <CreateCityCollectionModal
                        toggleAddCities={toggleAddCities}
                        reload={reload}
                        onClose={(created) => {
                                if(created) message.success("Dataset successfully created")
                                toggleCreate(false) 
                             }}
                    />
                )}

                {addToCityCollection && (
                    <AddCitiesToCollection
                        dataset={addToCityCollection}
                        onClose={() => toggleAddCities(undefined)}
                    />
                )}
        </Page>
    )
}

export default CityCollections