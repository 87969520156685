import React, { useRef } from 'react'
import { useScrollPosition } from '../../hooks'
import { Icon } from '../Theme'
import styles from './index.module.css'

const Page = ({
    title,
    customTitle,
    icon,
    help,
    customHelp,
    children,
    buttons,
}) => {
    const { y } = useScrollPosition()
    const buttonsTop = useRef()

    return (
        <div className={styles.page}>
            <section>
                <div className={styles.header}>
                    {customTitle || (
                        <>
                            <Icon
                                icon={icon}
                                size={2.5}
                            />
                            <h1>{title}</h1>
                        </>
                    )}
                </div>

                {children}
            </section>
            <aside>
                {(customHelp || help) && (
                    <div className={styles.help}>
                        {customHelp || (
                            <>
                                <h2>{help.title}</h2>
                                <p>{help.description}</p>
                                
                                {help.sections.map(
                                    ([title, description]) => (
                                        <div key={`section-info-${title}`}>
                                            <h3>{title}</h3>
                                            {description}
                                        </div>
                                    )
                                )}
                            </>
                        )}
                    </div>
                )}
                {buttons && (
                    <div
                        ref={ref => {
                            if (ref) {
                                buttonsTop.current = ref.offsetTop
                            }
                        }}>
                            <div 
                                className={styles.buttons}
                                data-fixed={y > buttonsTop.current}>
                                    {buttons}
                            </div>
                    </div>
                )}
            </aside>
        </div>
    )
}

export default Page