import React from "react";
import styles from './index.module.css'
import { Button } from '../../../Theme'
import ImageUploader from './ExperienceImageUploader'


const MediaItem = ({name, url, setName, setUrl, action, onAction, disabled = false}) => {
    return (
         <tr>
            <td className={styles.imgCol}>
                <div className={styles.image}>
                    <ImageUploader 
                        value={url} 
                        onValueChange={path => setUrl(path)}
                    />
                </div>
            </td>
            <td className={styles.descriptionWrapper}>
                <div className={styles.description}>
                    <textarea 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
            </td>
            <td className={styles.action}>
                {action && <Button 
                    color="Black" 
                    // style={{width: 180}}
                    onClick={onAction}
                    disabled={disabled}
                    primary>
                        {action}
                </Button>}
            </td>
         </tr>
    )
}

export default MediaItem