import { gql } from 'apollo-boost'
import { PED_URL } from '../../constants'

export const GET_POD_SETTINGS = gql`
    query getPedSettings($cityCollectionId: ID){
        getPedSettings(cityCollectionId: $cityCollectionId) {
            welcomeText1
            welcomeText2
            welcomeText3
            welcomeImage
            welcomeButton
            travelingForImage
            travelingForText
            question1
            question2
            question3
            keywords{
                shopping
                nature
                cultural
                arts
                food
                relaxing
                thrills
                historical
                souvenirs
                romantic
                museum
                beauty
                bars
                games
                thriftstores
                dining
            }
            airlineLogo
            resultMessage
            loadingText
            resultText
        }
    }
`
export const UPDATE_POD_SETTINGS = gql`
    mutation updatePedSettings(
        $cityCollectionId: ID
        $welcomeText1: String,
        $welcomeText2: String,
        $welcomeText3: String,
        $welcomeImage: String,
        $welcomeButton: String,
        $question1: String,
        $question2: String,
        $question3: String,
        $keywords: Keywords
        $airlineLogo: String,
        $resultMessage: String,
        $loadingText: String,
        $resultText: String,
        $travelingForText: String,
        $travelingForImage: String,

    ) {
        updatePedSettings(
            cityCollectionId: $cityCollectionId,
            welcomeText1: $welcomeText1,
            welcomeText2: $welcomeText2,
            welcomeText3: $welcomeText3,
            welcomeImage: $welcomeImage,
            welcomeButton: $welcomeButton,
            question1: $question1,
            question2: $question2,
            question3: $question3,
            keywords: $keywords
            airlineLogo: $airlineLogo,
            resultMessage: $resultMessage,
            loadingText: $loadingText,
            resultText: $resultText,
            travelingForText: $travelingForText,
            travelingForImage: $travelingForImage,
            )
    }
`

export const DEFAULT_PED_SETTINGS = {
            welcomeText1: 'Aloha!',
            welcomeText2: 'Welcome aboard!,            ',
            welcomeText3: 'We’d love to show what’s waiting for you in Lanai!',
            welcomeImage: `${PED_URL}/images/home.jpg`,
            welcomeButton: 'Let`s go',
            question1: 'Which photos do you envision for your trip?📸',
            question2: 'Which photos do you identify with?🙂',
            question3: 'What’s your perfect morning routine on vacation?☀️',
            travelingForText: 'I am visiting Lanai for',
            travelingForImage: `${PED_URL}/images/step1.jpg`,
            keywords: {
                shopping: `${PED_URL}/images/shopping.jpg`,
                nature: `${PED_URL}/images/nature.jpg`,
                cultural: `${PED_URL}/images/cultural.jpg`,
                arts: `${PED_URL}/images/arts.jpg`,
                food: `${PED_URL}/images/food.jpg`,
                relaxing: `${PED_URL}/images/relaxing.jpg`,
                thrills: `${PED_URL}/images/thrills.jpg`,
                historical: `${PED_URL}/images/historical.jpg`,
                souvenirs: `${PED_URL}/images/souvenirs.jpg`,
                romantic: `${PED_URL}/images/romantic.jpg`,
                polynesia: `${PED_URL}/images/polynesia.jpg`,
                museum: `${PED_URL}/images/museum.jpg`,
                beauty: `${PED_URL}/images/beauty.jpg`,
                bars: `${PED_URL}/images/bars.jpg`,
                games: `${PED_URL}/images/games.jpg`,
                thriftstores: `${PED_URL}/images/thriftstores.jpg`,
                dining: `${PED_URL}/images/dining.jpg`,
            },
            airlineLogo: `${PED_URL}/icons/light/ten.svg`,
            resultMessage: 'Your Top Ten List',
            loadingText: '',
            resultText: 'So you’re off to [city]. Amazing! Our local experts hooked up with Olivr (RAD’s personal AI brain) to curate these 10 awesome experiences just for your trip to [city nickname].',
}