import React, { useState, useRef } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { TOGGLE_ITEMS_IN_CITY_COLLECTION } from '../../../mutations'
import { Modal, Button, Input } from '../../Theme'
import CitiesList from '../../CitiesList'
import styles from './index.module.css'

const AddCitiesToCollection = ({
    dataset,
    onClose,
}) => {
    const [name, setName] = useState('')
    const selected = useRef(new Set())
    const [toggleItems, { loading }] = useMutation(TOGGLE_ITEMS_IN_CITY_COLLECTION)

    const onSelect = (id) => {
        if (selected.current.has(id)) {
            selected.current.delete(id)
        } else {
            selected.current.add(id)
        }
    }

    const submit = () => {
        toggleItems({
            variables: {
                id: dataset,
                model: 'City',
                ids: [...selected.current],
            }
        })
        .then(onClose)
        .catch(console.error)
    }

    return (
        <Modal
            title="Add Cities to Dataset"
            showClose={true}
            wrapBody={true}
            onClose={onClose}
        >
            <div>
                <div className={styles.body}>
                    <h2>Maybe start with adding these cities?</h2>

                    <Input 
                        placeholder="City Name"
                        onValueChange={value => setName(value)}
                    />

                    <CitiesList
                        height="40vh"
                        filters={{name}}
                        onSelect={onSelect}
                    />
                </div>
                <div className={styles.footer}>
                    <Button
                        style={{width: 180}}
                        onClick={onClose}>
                            Cancel
                    </Button>
                    <Button 
                        color="blue" 
                        style={{width: 180}}
                        onClick={submit}
                        loading={loading}
                        primary>
                            Add
                    </Button>
                    <Button
                        style={{width: 250}}
                        onClick={onClose}>
                            Create Blank Dataset
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default AddCitiesToCollection