import React, { useState, useMemo, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import { Checkbox } from '../Theme'
import { useEffectSkipFirst, useForceUpdate } from '../../hooks'
import { CLOUDINARY_URL } from '../../constants'
import Loader from '../Loader'
import ExperiencesModal from '../ExperiencesModal' 
import EmptyResult from '../EmptyResult'
import { useUser } from '../../hocs/auth'
import { RADMIN_URL } from '../../constants'
import styles from './index.module.css'
const DELETE_ACTIVITIES = gql`
    mutation deleteActivity (
        $id: ID!,
    ) {
        deleteActivity (id: $id) {
            success
    }
}
`

const GET_ACTIVITIES = gql`
    query getActivities (
        $sortBy: String,
        $sortDirection: Int,
        $city: String,
        $country: String,
        $page: Int,
        $name: String,
        # $categories: [String],
        $dataset: String,
        $explorer: ID,
        $guide: ID,
    ) {
        activities(
            sortBy: $sortBy,
            sortDirection: $sortDirection,
            city: $city,
            country: $country,
            page: $page,
            name: $name,
            # categories: $categories,
            dataset: $dataset,
            explorer: $explorer,
            guide: $guide,
        ) {
            _id,
            name,
            description,
            recommendation_tags,
            category,
            _explorer {
                _id,
                full_name,
                email
            },
            google_info {
                formatted_address
            },
            tags,
            medias {
                standard_resolution {
                    name
                    url
                },
                cover,
            },
            updated_date,
        }
    }
`

const ExperiencesList = ({
    filters,
    onSelect,
}) => {
    const [page, setPage] = useState(1)
    const[deleteActivities, { data: deleteResponse, loading: deleteLoading }] = useMutation(DELETE_ACTIVITIES)
    const [editableExperience, setEditableExperience] = useState(false)
    const [reloaded, reload] = useForceUpdate()
    const {
        loading: activitiesLoading,
        data: activitiesResponse,
        fetchMore: fetchMoreActivities,
        refetch: loadActivities,
    } = useQuery(GET_ACTIVITIES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        variables: filters,
    })
    const token = useMemo(() => {
        return localStorage.getItem('token')
    }, [])
    const { user } = useUser() 

    const onDeleteActivities = (id) => {
        const confirmDelete = window.confirm("Are you sour you want delete selected Activities")

        if(confirmDelete) {
            return deleteActivities({
                variables: {
                    id: id
                }
            })
            .then(() => reload())
        } 

    }

    const loadMoreActivities = () => {
        fetchMoreActivities({
            variables: {
                page: page + 1
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev
                }

                setPage(
                    page => page + 1
                )

                return Object.assign(
                    {},
                    prev,
                    {
                        activities: [
                            ...prev.activities,
                            ...fetchMoreResult.activities
                        ]
                    }
                );
            }
        })
    }

    const onImageError = (e) => {
        e.target.src = "https://res.cloudinary.com/dbur3sjwh/image/upload/v1590513468/blur_fsxeji.jpg"
    }

    useEffectSkipFirst(() => {
        loadActivities(filters)
            .catch(console.error)
    }, [filters])

    useEffect(() => {
        loadActivities()
    }, [reloaded])

    return (
        <div>
            {activitiesResponse && (
                <ul className={styles.activitiesWrapper}>
                    <InfiniteScroll
                        dataLength={activitiesResponse.activities.length}
                        next={loadMoreActivities}
                        hasMore={true}>
                            {activitiesResponse.activities.length > 0 ? activitiesResponse.activities.map(
                                (activity, i) => (
                                    <li 
                                        key={`activity-${activity._id}`}
                                        className={styles.activity}>
                                            {onSelect && (
                                                <div className="ml-1 mr-1">
                                                    <Checkbox
                                                        onChange={() => {
                                                                onSelect(activity)
                                                            }
                                                        }
                                                    />
                                                </div>
                                            )}
                                            <div 
                                                onClick={() => window.open(`${RADMIN_URL}/activity/${activity._id}`)}
                                                className={styles.activityCover}>
                                                {activity.medias[0] && (
                                                    <img 
                                                        src={CLOUDINARY_URL + '/w_250,f_auto/' + (activity.medias.find(media => media.cover)?.standard_resolution?.url || activity.medias[0].standard_resolution.url)}
                                                        onError={onImageError}
                                                        alt={activity.name + 'cover'}
                                                    />
                                                )}
                                            </div>
                                            <div className={styles.activityBody}>
                                                <h3>{activity.name}</h3>
                                                <p>
                                                    {activity.updated_date && (
                                                        <span>
                                                            Modified on 
                                                            <span className="mr-1 ml-1">{moment(activity.updated_date).format('MMM DD')}</span>
                                                        </span>
                                                    )}
                                                    {activity.category}
                                                    {user?.permissions?.editActivity && <a 
                                                        target="_blank"
                                                        className={styles.editButton} 
                                                        href={`${RADMIN_URL}/edit/activity/${activity._id}?org_token=${token}&redirect_url=${window.location.href}`}
                                                        >
                                                        Edit
                                                    </a>}
                                                    {user?.permissions?.deleteActivity && <span 
                                                        target="_blank"
                                                        className={styles.deleteButton} 
                                                        onClick={() => onDeleteActivities(activity._id)}
                                                        // href={`${RADMIN_URL}/edit/activity/${activity._id}?org_token=${token}&redirect_url=${window.location.href}`}
                                                        >
                                                        Delete
                                                    </span>}
                                                    {/* <a 
                                                        target="_blank"
                                                        className={styles.editButton} 
                                                        href={`${RADMIN_URL}/activity/${activity._id}`}
                                                        >
                                                        Preview
                                                    </a> */}
                                                </p>
                                                <p>
                                                    {activity?.tags?.length > 0 && (
                                                        // Keywords/
                                                        <>
                                                            <span style={{color: 'black'}}>
                                                                {'Keywords '}
                                                            </span>
                                                            <span>
                                                                {activity.tags.map(tag => 
                                                                    <span key={tag}>{tag} </span>
                                                                )}
                                                            </span>
                                                        </>
                                                    )}
                                                </p>
                                                <p className="color-black">{activity.google_info.formatted_address}</p>
                                                {activity._explorer && (
                                                    <p className="color-black">{'By '}
                                                        <a 
                                                            target="_blank" className={styles.explorerLink}
                                                            href={activity._explorer.email 
                                                                ? `${RADMIN_URL}/e/${activity._explorer.email}`
                                                                : `${RADMIN_URL}/e/${activity._explorer._id}`
                                                            }
                                                        >
                                                            {activity._explorer.full_name}
                                                        </a>
                                                    </p>
                                                )}
                                                <p>{activity.description}</p>
                                            </div>
                                    </li>
                                )
                            ) : <EmptyResult/>} 
                    </InfiniteScroll>
                </ul>
            )}
    
            {activitiesLoading && (
                <Loader/>
            )}
            {editableExperience && (
                    <ExperiencesModal
                        experience={editableExperience}
                        onClose={() => {
                            setEditableExperience(false)
                            }
                        }
                    />
                )}
        </div>
    )
}

export default ExperiencesList