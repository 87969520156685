import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ScrollTop } from '../components/Theme'


const withRouter = Component => ({
    ...props
}) => {
    return (
        <BrowserRouter>
            <ScrollTop />
            <Component {...props}/>
        </BrowserRouter>
    )
}

export default withRouter