import React from 'react'
import styles from './index.module.css'
import TextField from '../PedTextField'
import MediaItem from '../PedMediaItem'


const WelcomeForm = ({
pedSettings,
    onChange,
}) => {
    return (
        <>
        <div className={styles.form}>    
        <table border-collapse="true" className={styles.table}>
            <thead>
                <tr>
                    <th>Field</th>
                    <th>Content</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
            <TextField
                label="Welcome Text 1"
                onChange={value => {
                    onChange({'welcomeText1': value})}
                }
                value={pedSettings.welcomeText1}
            />
            <TextField 
                label="Welcome Text 2"
                value={pedSettings.welcomeText2}
                onChange={(value) => {
                        onChange({ 'welcomeText2': value })
                    }
                }

            />
            <TextField 
                label="Welcome Text 3"
                value={pedSettings.welcomeText3}
                onChange={(value) => {
                        onChange({ 'welcomeText3': value })
                    }
                }
            />
            <TextField 
                label="Button Content"
                value={pedSettings.welcomeButton}
                onChange={(value) => {
                    onChange({ 'welcomeButton': value })
                    }
                }
            />



            <MediaItem 
                        url={pedSettings.welcomeImage}
                        name="Cover"
                        setUrl={(path) => onChange({ 'welcomeImage':path })}

                    />
            </tbody>
        </table>
        </div>
        </>
    )
}

export default WelcomeForm