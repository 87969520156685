import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import { useEffectSkipFirst } from '../../hooks'
import { Checkbox } from '../Theme'
import Loader from '../Loader'
import { CLOUDINARY_URL } from '../../constants'
import styles from './index.module.css'

const GET_VIDEOS = gql`
    query getVideos (
        $name: String,
        $sortBy: String,
        $page: Int,
        $dataset: String,
    ) {
        videos (
            name: $name,
            sortBy: $sortBy,
            page: $page,
            dataset: $dataset,
        ) {
            _id,
            name,
            description,
            url,
            thumbnail,
            status,
            created_date,
        }
    }
`

const VideosList = ({
    filters,
    onSelect = () => { },
}) => {
    let history = useHistory()
    const [page, setPage] = useState(1)

    const {
        loading: videosLoading,
        data: videosResponse,
        fetchMore: fetchMoreVideos,
        refetch: loadVideos,
    } = useQuery(GET_VIDEOS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        variables: filters,
    })

    const loadMoreVideos = () => {
        fetchMoreVideos({
            variables: {
                page: page + 1
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev
                }

                setPage(
                    page => page + 1
                )

                return Object.assign(
                    {},
                    prev,
                    {
                        videos: [
                            ...prev.videos,
                            ...fetchMoreResult.videos
                        ]
                    }
                );
            }
        })
    }

    const onImageError = (e) => {
        e.target.src = "https://res.cloudinary.com/dbur3sjwh/image/upload/v1590513468/blur_fsxeji.jpg"
    }

    useEffectSkipFirst(() => {
        loadVideos(filters)
            .catch(console.error)
    }, [filters])

    return (
        <div>
            {videosResponse && (
                <ul className={styles.videosWrapper}>
                    <InfiniteScroll
                        dataLength={videosResponse.videos.length}
                        next={loadMoreVideos}
                        hasMore={true}>
                            {videosResponse.videos.map(
                                video => (
                                    <li 
                                        key={`video-${video._id}`}
                                        className={styles.video}>
                                            {onSelect && (
                                                <div className="ml-1 mr-1">
                                                    <Checkbox
                                                        onChange={() => onSelect(video._id)}
                                                    />
                                                </div>
                                            )}
                                        <div className={styles.videoItem} onClick={() => history.push(`videos/${video._id}`)}>
                                            <div className={styles.videoCover}>
                                                <img 
                                                    src={CLOUDINARY_URL + '/w_700,f_auto/' + video.thumbnail}
                                                    onError={onImageError}
                                                    alt={video.name + 'thumbnail'}
                                                />
                                            </div>
                                            <div className={styles.videoBody}>
                                                <h3>{video.name}</h3>
                                                {video.status === 'uploaded' ? (
                                                    <p>
                                                        Uploaded on
                                                        <span className="mr-1 ml-1">
                                                            {moment(video.updated_date).format('MMM DD')}
                                                        </span>
                                                    </p>
                                                ) : (
                                                    <p>{video.status}</p>
                                                )}
                                                <p>{video.description}</p>
                                            </div>
                                        </div>
                                    </li>
                                )
                            )}
                    </InfiniteScroll>
                </ul>
            )}

            {videosLoading && (
                <Loader/>
            )}
        </div>
    )
}

export default VideosList