import React, { useEffect, useReducer } from 'react'
import { useQueryParam } from '../../hooks'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Icon, Input, Switch } from '../Theme'
import Loader from '../Loader'
import CityCountryFilters from '../CityCountryFilters'
import Page from '../Page'
import ExperiencesList from '../ExperiencesList'
import BreadCumps from '../BreadCrumps'
import { CLOUDINARY_URL } from '../../constants'
import styles from './index.module.css'

const GET_GUIDE = gql`
    query getGuide (
        $id: ID,
    ) {
        guide (
            id: $id,
        ) {
            _id,
            name,
            description,
            cover {
                standard_resolution {
                    url
                }
            },
            _explorer {
                full_name
            },
            created_date,
        }
    }
`

const Guide = () => {
    const source = useQueryParam('source')
    const { id } = useParams()
    const { data: guideResponse, loading: loadingGuide } = useQuery(GET_GUIDE, {
        partialRefetch: true,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        variables: {
            id
        }
    })

    const [filters, setFilter] = useReducer(
        (state, value) => ({ ...state, ...value }),
        {
            name: '',
            sortBy: 'modified',
            city: undefined,
            country: undefined,
        }
    )
    const guidesBreadcumps = source.split(",")

    if (loadingGuide || !guideResponse ) {
        return <Loader />
    }

    const { guide } = guideResponse

    return (
        <Page
            customTitle={<>
                <Icon 
                    icon="datasets"
                    size={2.5}/>
                <h1 className={styles.title}>
                    <BreadCumps breadcumps={guidesBreadcumps} activeName={guide.name}/>
                </h1>
            </>}
            help={{
                title: 'How Guides Work',
                description: 'This section allows you to add Guides to your dataset.',
                sections: [
                    [
                        'Organize:',
                        <p>Search Guides using the search box or the drop down.  When you locate the Guides you want in your Dataset, click on the checkbox to the left of their photo to include them.</p>
                    ],
                    [
                        'Implement:',
                        <p>When you feel good about your list, click ADD ITEMS TO DATASET+ below to add all checked items, then name your Dataset. If you have any questions, <a href="mailto:masha@rad.travel">email us</a>. We are here for you.</p>
                    ]
                ]
            }}>
                <div className={styles.guide}>
                    <div className={styles.guideCover}>
                        {guide.cover && (
                            <img 
                                src={CLOUDINARY_URL + '/w_250,f_auto/' + guide.cover.standard_resolution.url}
                                alt={guide.name + 'cover'}
                            />
                        )}
                    </div>
                    <div className={styles.guideBody}>
                        <h3>{guide.name}</h3>
                        <p>
                            Published on 
                            <span className="mr-1 ml-1">
                                {moment(guide.created_date).format('MMM DD')}
                            </span>
                        </p>
                        {guide._explorer && (
                            <p className="color-black">
                                By {guide._explorer.full_name}
                            </p>
                        )}
                        <p>{guide.description}</p>
                    </div>
                </div>

                <Input
                    placeholder="Search By Keyword"
                    icon="search"
                    onValueChange={value => setFilter({ 'name': value })}
                />
                <div>
                    <span className="mr-2">Sort By</span>
                    <Switch
                        value={filters.sortBy}
                        onChange={value => setFilter({'sortBy': value })}
                        values={[
                            ['Modified', 'modified'],
                            ['Name', 'name'],
                        ]}
                    />
                </div>

                <CityCountryFilters
                    cityValue={filters.city}
                    countryValue={filters.country}
                    onCityChange={setFilter}
                    onCountryChange={setFilter}
                    // filters={{guide: id}}
                />

                <ExperiencesList
                    filters={{
                        ...filters,
                        guide: id,
                    }}
                />
        </Page>
    )
}

export default Guide