import {useState} from 'react'
import CONFIG from '../config'
import AWS from "aws-sdk"

const S3_CONFIG = CONFIG.S3_CONFIG
AWS.config.update({
    region: S3_CONFIG.region,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: S3_CONFIG.IdentityPoolId
    })
})

const useImageUploader = () => {
    const [loading, setLoading] = useState(false)

    const uploader = async (file) => {
        const params = {
            Key: Date.now() + file.name,
            Body: file,
            ACL: S3_CONFIG.ACL,
        }
        const s3 = new AWS.S3({
            apiVersion: S3_CONFIG.apiVersion,
            params: {
                Bucket: S3_CONFIG.bucket
            }
            })
     
    
        return new Promise((resolve, reject) => {
            setLoading(true)
            s3.upload(params, (err, data) => {
                setLoading(false)
                if (err) {
                    console.log({ err })
                    reject(err)
                }
                resolve(data)
            })
        })

    }
    return {loading, uploader}
}

export default useImageUploader