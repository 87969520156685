import React, { useReducer } from 'react'
import { message } from 'antd'
import { Link, useParams, useHistory } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { Input, Button } from '../Theme'
import styles from './index.module.css'

const RESET_PASSWORD = gql`
    mutation resetPassword (
        $password: String!,
        $passwordConfirmation: String!,
        $code: String!,
    ) {
        resetPassword (
            password: $password,
            passwordConfirmation: $passwordConfirmation,
            code: $code,
        ) {
            success,
        }
    }
`

const ResetPassword = () => {
    const { code } = useParams()
    const history = useHistory()
    const [resetPassword] = useMutation(RESET_PASSWORD)
    const [variables, set] = useReducer(
        (state, [field, value]) => ({...state, [field]: value}),
        {
            password: '',
            passwordConfirmation: '',
        }
    )

    const submit = () => resetPassword({
        variables: {
            ...variables,
            code,
        }
    }).then(() => {
        message.success('Password successfully updated! Please login.')
        history.push('/')
    }).catch(e => {
        const error = e.graphQLErrors && e.graphQLErrors[0]
        if (error) message.error(error.message)
    })

    return (
        <div>
            <div className={styles.backToLogin}>
                <Link to="/">
                    Sign In
                </Link>
            </div>
            <Input 
                type="password"
                label="* New Password"
                placeholder="New Password"
                onChange={e => set(['password', e.target.value])}
                onEnter={submit}
            />
            <Input 
                type="password"
                label="* Confirm Password"
                placeholder="Confirm Password"
                onChange={e => set(['passwordConfirmation', e.target.value])}
                onEnter={submit}
            />
            <div className={styles.buttonWrapper}>
                <Button 
                    onClick={submit}
                    style={{width: 150}}
                    primary>
                        Submit
                </Button>
            </div>
        </div>
    )
}

export default ResetPassword