import React, { useReducer, useEffect, useCallback, useState } from 'react'
import { message } from 'antd'
import { gql } from 'apollo-boost'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { Modal, Button } from '../Theme'
import OrganizationForm from './OrganizationForm'
import OrganizationPermissionsForm from './OrganizationPermissionsForm'
import styles from './index.module.css'
import ImageUpload from '../ImageUpload'
import { PERMISSIONS_LIST } from './OrganizationForm/configs' 

const GET_ORGANIZATION = gql`
    query getOrganization ($id: ID) {
        organization (id: $id) {
            email,
            fullName,
            orgname,
            orgalias,
            logo,
            permissions,
        }
    }
`
const CREATE_ORGANIZATION = gql`
    mutation createOrganization (
        $email: String!,
        $password: String!,
        $fullName: String!,
        $orgname: String!,
        $orgalias: String!
        $logo: String,
        $permissions: String,
    ) {
        createOrganization (
            email: $email,
            password: $password,
            fullName: $fullName,
            orgname: $orgname,
            orgalias: $orgalias
            logo: $logo,
            permissions: $permissions,
        ) {
            _id
        }
    }
`
const UPDATE_ORGANIZATION = gql`
    mutation updateOrganization (
        $id: ID,
        $email: String,
        $password: String,
        $fullName: String,
        $orgname: String,
        $orgalias: String
        $logo: String,
        $permissions: String,
    ) {
        updateOrganization (
            id: $id,
            email: $email,
            password: $password,
            fullName: $fullName,
            orgname: $orgname,
            orgalias: $orgalias
            logo: $logo,
            permissions: $permissions,
        ) {
            success
        }
    }
`

const OrganizationModal = ({
    organization,
    reload,
    onClose,
}) => {
    const [orgRequest, { loading }] = useMutation(organization ? UPDATE_ORGANIZATION : CREATE_ORGANIZATION)
    const [getOrg, { data: orgResponse }] = useLazyQuery(
            GET_ORGANIZATION,
            { 
                variables: { id: organization },
                fetchPolicy: 'cache-and-network',
            }
        )
    const [orgData, setOrgData] = useReducer(
             (state, value) => ({...state, ...value}),
             {
                 orgname:""
             }
        )
    const [permissions, setPermission] = useState({})

    useEffect(() => {
        if(organization) {
            getOrg()
        }
    }, [getOrg, organization])

    useEffect(() => {
        const displayPermissions = {}
        
        if(!organization) {

            Object.keys(PERMISSIONS_LIST).forEach(option => {
                displayPermissions[option] = false
            })

        }

        if(organization && orgResponse) {
            const fullName = orgResponse.organization.fullName
            const [firstName, lastName] = fullName ? fullName.split(' ') : ['', '']
            const {__typename, ...org } = orgResponse.organization
            const permissions = org.permissions 
                ? JSON.parse(org.permissions)
                : {}
                Object.keys(PERMISSIONS_LIST).forEach(option => {
                displayPermissions[option] = permissions[option] || false
            })
            console.log("#art", org)
            setOrgData({...org, firstName: firstName, lastName: lastName})
        }

        setPermission(displayPermissions)
    }, [orgResponse, organization])

    const submit = () => {
        const {confirmPassword, firstName, lastName, ...data } = orgData
        if(!data.password) return message.error("Passwords is required")
        if(data.password && data.password !== confirmPassword) {
            message.error("Passwords don't match")
            return
        }
        const requestData =  { 
            ...data,
            fullName: `${firstName} ${lastName}`,
            id: organization,
            permissions: JSON.stringify(permissions)
        }
        // remove undefined fields
        Object.keys(requestData).forEach(key => requestData[key] === undefined && delete requestData[key])

        orgRequest({ variables: requestData })
            .then(() => {
                message.success(`Partner successfully ${organization ? "updated" : "created"}`)
                reload()
                onClose()
            })
            .catch(e => {
                const error = e.graphQLErrors && e.graphQLErrors[0]
                error ? message.error(error) : message.error(e.message)
            })
    }

    return (
        <Modal
            title="Create Partner"
            showClose={true}
            wrapBody={true}
            onClose={onClose}
            maxWidth={1200}
        >
            <div className={styles.bodyWrapper}>
                <div className={styles.body}>
                    <OrganizationForm 
                        organization={orgData} 
                        onChange={setOrgData}
                    />
                    <div className={styles.image}>
                        <div className={styles.uploaderWrapper}>
                            <ImageUpload 
                                    value={orgData.logo} 
                                    name="Logo"
                                    onValueChange={url => setOrgData({ 'logo': url })}
                                />
                        </div>
                        <OrganizationPermissionsForm 
                            list={PERMISSIONS_LIST}
                            permissions={permissions || {}}
                            onChange={setPermission}
                        />
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button
                        style={{width: 180}}
                        onClick={onClose}>
                            Cancel
                    </Button>
                    <Button 
                        color="blue" 
                        style={{width: 180}}
                        onClick={submit}
                        loading={loading}
                        primary>
                            Save
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default OrganizationModal