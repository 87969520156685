import React, { useReducer } from 'react'
import { message } from 'antd'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { Input, Modal, Button, Icon } from '../../Theme'
import styles from './index.module.css'

const CHANGE_PASSWORD = gql`
    mutation changePassword (
        $oldPassword: String!,
        $password: String!,
        $passwordConfirmation: String!,
    ) {
        changePassword (
            oldPassword: $oldPassword,
            password: $password,
            passwordConfirmation: $passwordConfirmation,
        ) {
            success,
        }
    }
`

const ChangePasswordModal = ({
    onClose,
}) => {
    const [changePassword] = useMutation(CHANGE_PASSWORD)

    const [variables, set] = useReducer(
        (state, [field, value]) => ({...state, [field]: value}),
        {
            oldPassword: '',
            password: '',
            passwordConfirmation: '',
        }
    )

    const submit = (e) => {
        changePassword({ variables })
            .then(() => {
                message.success('Password was successfully changed!')
                onClose()
            })
            .catch(e => {
                const error = e.graphQLErrors && e.graphQLErrors[0]
                if (error) message.error(error.message)
            })
    }

    return (
        <Modal onClose={onClose}>
            <div className={styles.header}>
                <Button onClick={onClose}>
                    <Icon
                        icon="large_close"
                        size={2}
                    />
                </Button>
                <h1>Change Password</h1>
            </div>
            <div className={styles.body}>
                <div>
                    <Input
                        label="Current Password"
                        type="password"
                        placeholder="OLD PASSWORD"
                        value={variables.oldPassword}
                        onChange={e => set(['oldPassword', e.target.value])}
                    />
                    <Input
                        label="New Password"
                        type="password"
                        placeholder="NEW PASSWORD"
                        value={variables.password}
                        onChange={e => set(['password', e.target.value])}
                    />
                    <Input
                        label="Password Confirmation"
                        type="password"
                        placeholder="PASSWORD CONFIRMATION"
                        value={variables.passwordConfirmation}
                        onChange={e => set(['passwordConfirmation', e.target.value])}
                    />
                </div>
            </div>
            <div className={styles.footer}>
                <Button
                    style={{width: 180}}
                    onClick={onClose}>
                        Cancel
                </Button>
                <Button 
                    color="blue" 
                    style={{width: 180}}
                    onClick={submit}
                    primary>
                        Save
                </Button>
            </div>
        </Modal>
    )
}

export default ChangePasswordModal