import React from 'react'
import styles from './index.module.css'
import MediaItem from '../PedMediaItem'
import TextField from '../PedTextField'


const Step2Form = ({
    pedSettings,
    onChange,
}) => {
    return (
        <div className={styles.container}>
        <table border-collapse="true" className={styles.table}>
            <thead>
                <tr>
                    <th>Field</th>
                    <th>Content</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <TextField
                    onChange={value => {
                        onChange({'question2': value})}
                    }
                    label='Question'
                    value={pedSettings.question2 }
                />
                <MediaItem 
                    resize={true}
                    name='Souvenirs'
                    url={pedSettings.souvenirs}
                    setUrl={(value => onChange({souvenirs: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Romantic'
                    url={pedSettings.romantic}
                    setUrl={(value => onChange({romantic: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Polynesia'
                    url={pedSettings.polynesia}
                    setUrl={(value => onChange({polynesia: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Museum'
                    url={pedSettings.museum}
                    setUrl={(value => onChange({museum: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Beauty'
                    url={pedSettings.beauty}
                    setUrl={(value => onChange({beauty: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Bars'
                    url={pedSettings.bars}
                    setUrl={(value => onChange({bars: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Games'
                    url={pedSettings.games}
                    setUrl={(value => onChange({games: value}))}
                />
                <MediaItem 
                    resize={true}
                    name='Thriftstores'
                    url={pedSettings.thriftstores}
                    setUrl={(value => onChange({thriftstores: value}))}
                />
                <MediaItem 
                    name='Dining'
                    resize={true}
                    url={pedSettings.dining}
                    setUrl={(value => onChange({dining: value}))}
                />
            </tbody>
        </table>
        </div>
    )
}

export default Step2Form