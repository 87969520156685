import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Checkbox, Button } from '../Theme'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useEffectSkipFirst } from '../../hooks'
import { CLOUDINARY_URL } from '../../constants'
import Loader from '../Loader'
import styles from './index.module.css'
import { useUser } from '../../hocs'

const GET_ORGANIZATIONS = gql`
    query getOrganizations (
        $orgname: String,
        $sortBy: String,
        $page: Int,
    ) {
        organizations (
            orgname: $orgname,
            sortBy: $sortBy,
            page: $page,
        ) {
            _id,
            orgname,
            logo,
        }
    }
`
const LOGIN_AS_PARTNER = gql`
    mutation loginAsPartner (
        $partnerId: ID!
    ) {
        loginAsPartner (
            partnerId: $partnerId
        ) {
            token
        }
    }
`

const OrganizationsList = ({
    filters,
    onSelect,
    onClick
}) => {
    const history = useHistory()
    const {getUser} = useUser()

    const [page, setPage] = useState(1)

    const [loginAsPartner] = useMutation(LOGIN_AS_PARTNER)

    const {
        loading: organizationsLoading,
        data: organizationsResponse,
        fetchMore: fetchMoreOrganizations,
        refetch: loadOrganizations,
    } = useQuery(GET_ORGANIZATIONS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        variables: filters,
    })

    const loadMoreOrganizations = () => {
        fetchMoreOrganizations({
            variables: {
                page: page + 1
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev
                }

                setPage(
                    page => page + 1
                )

                return Object.assign(
                    {},
                    prev,
                    {
                        organizations: [
                            ...prev.organizations,
                            ...fetchMoreResult.organizations
                        ]
                    }
                );
            }
        })
    }

    useEffectSkipFirst(() => {
        loadOrganizations(filters)
            .catch(console.error)
    }, [filters])

    const onLoginAsPartner = (partnerId) => {
        loginAsPartner({ variables: { partnerId } })
            .then(res => {
                if (!res) {
                    return
                }
                localStorage.setItem('token', res.data.loginAsPartner.token)
                getUser()
                history.push('/')
            })
    }

    const onImageError = (e) => {
        e.target.src = "https://res.cloudinary.com/dbur3sjwh/image/upload/v1590513469/placeholder-profile_omjhxm.jpg"
    }

    return (
        <div>
            {organizationsResponse && (
                <ul className={styles.organizationsWrapper}>
                    <InfiniteScroll
                        dataLength={organizationsResponse.organizations.length}
                        next={loadMoreOrganizations}
                        hasMore={true}>
                            {organizationsResponse.organizations.map(
                                organization => (
                                    <li 
                                        key={`organization-${organization._id}`}
                                        className={styles.organization}>
                                        <div className={styles.organizationData}>
                                            {onSelect && (
                                                <div className="ml-1 mr-1">
                                                    <Checkbox
                                                        onChange={(value) => onSelect(organization._id)}
                                                    />
                                                </div>
                                            )}
                                            {/* <div className={styles.organizationImage}>
                                                <img 
                                                    src={CLOUDINARY_URL + '/w_100,f_auto/' + organization.logo}
                                                    onError={onImageError}
                                                    alt={organization.orgname + ' profile'}
                                                />
                                            </div> */}
                                            <div className={styles.organizationBody}>
                                                <Link onClick={() => onClick(organization._id)}>
                                                    <h3>{organization.orgname}</h3>
                                                </Link>
                                            </div>
                                            </div>
                                            {/* <span
                                                className={styles.partnerButton} 
                                                onClick={() => onLoginAsPartner(organization._id)} primary>
                                                    login as partner
                                            </span> */}
                                    </li>
                                )
                            )}
                    </InfiniteScroll>
                </ul>
            )}

            {organizationsLoading && (
                <Loader/>
            )}
        </div>
    )
}

export default OrganizationsList