import { useEffect, useRef } from 'react'

const useEffectSkipFirst = (callback, depends) => {
    const firstRun = useRef(true)

    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false
            return
        }

        callback()
    }, depends)
}

export default useEffectSkipFirst