import React from 'react'
import styles from './index.module.css'

const EmptyResult = () => (
    <p className={styles.emptyList}>
        <span role="img" aria-label="" >
            🔍 
        </span>
        {' No Results...'}
    </p>
)

export default EmptyResult