import React, { useReducer, useState, useRef } from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useUser } from '../../hocs/auth'
import { TOGGLE_ITEMS_IN_CITY_COLLECTION } from '../../mutations'
import { useForceUpdate } from '../../hooks'
import { Button, Icon, Input, Switch } from '../Theme'
import CityCountryFilters from '../CityCountryFilters'
import Page from '../Page'
import ExplorersList from '../ExplorersList'
import SelectCityCollectionModal from '../SelectCityCollectionModal'
import styles from './index.module.css'
import LoadingContainer from '../LoadingContainer'
import CreateExplorerModal from './CreateExplorerModal'

const GET_EXPLORERS_COUNT = gql`
    query getExplorersCount (
        $full_name: String,
        $city: String,
        $country: String,
    ) {
        explorersCount (
            full_name: $full_name,
            city: $city,
            country: $country,
        )
    }
`

const Explorers = () => {
    const [selectCollection, toggleSelectCollection] = useState(false)
    const [createExplorerModal, toggleExplorerModal] = useState(false)
    const [toggleItems, { loading: adding }] = useMutation(TOGGLE_ITEMS_IN_CITY_COLLECTION)
    const [selectedCount, setSelectedCount] = useState(0)
    const selected = useRef(new Set())
    const [reloaded, reload] = useForceUpdate()
    const { user } = useUser()


    const [filters, setFilters] = useReducer(
        (state, value) => ({ ...state, ...value }),
        {
            full_name: '',
            sortBy: '',
            country: undefined,
            city: undefined,
        }
    )

    const {
        data: explorersCountResponse,
        refetch: recountExplorers,
        loading: experiencesLoading
    } = useQuery(GET_EXPLORERS_COUNT)

    const filter = (value) => {
        setFilters(value)
        recountExplorers({ ...filters, ...value, explorersLoading: true })
            .catch(console.error)
    }

    const onSelect = (id) => {
        if (selected.current.has(id)) {
            selected.current.delete(id)
        } else {
            selected.current.add(id)
        }

        setSelectedCount(
            [...selected.current].length
        )
    }

    const add = (collections) => {
        Promise.all(
            collections.map(
                id => toggleItems({
                    variables: {
                        id,
                        ids: [...selected.current],
                        model: 'Explorer',
                    }
                })
            )
        ).then(() => {
            reload()
            selected.current = new Set()
            setSelectedCount(0)
        })
        .catch(console.error)
    }

    return (
        <Page
            title="Explorers"
            icon="Explorers"
            help={{
                title: 'How Explorers Work',
                description: 'This section allows you to add Explorers to your dataset.',
                sections: [
                    [
                        'Organize:',
                        <p>Search Explorers using the search box or the drop down.  When you locate the Explorers you want in your Dataset, click on the checkbox to the left of their photo to include them.</p>
                    ],
                    [
                        'Implement:',
                        <p>When you feel good about your list, click ADD ITEMS TO DATASET+ below to add all checked items, then name your Dataset. If you have any questions, <a href="mailto:masha@rad.travel">email us</a>. We are here for you.</p>
                    ]
                ]
            }}
            buttons={[
                <Button 
                    key="add-to-dataset"
                    onClick={() => toggleSelectCollection(true)}
                    disabled={selectedCount === 0 || undefined}
                    loading={adding}
                    primary>
                        <Icon 
                            icon="add"
                            size={2}
                        />
                        Add {selectedCount ? selectedCount : ''} Explorers To Dataset
                </Button>,
                (user.isAdmin || user?.permissions?.createExplorer) && <Button 
                    key="add-explorer"
                    onClick={() => toggleExplorerModal(true)}
                    loading={adding}
                    primary>
                        <Icon 
                            icon="add"
                            size={2}
                        />
                        Create Explorer
                </Button>,
            ]}>
                {createExplorerModal && <CreateExplorerModal 
                    onClose={() => toggleExplorerModal(false)}
                />}
                <div>
                    <Input
                        placeholder="Search by name"
                        icon="search"
                        onValueChange={value => filter({ 'full_name': value })}
                    />

                    <div>
                        <span className="mr-2">Sort By</span>
                        <Switch
                            value="updated_date"
                            onChange={value => filter({ 'sortBy': value })}
                            values={[
                                ['Modified', 'updated_date'],
                                ['Name', 'full_name'],
                            ]}
                        />
                    </div>
                    
                    <CityCountryFilters
                        cityValue={filters.city}
                        countryValue={filters.country}
                        onCountryChange={filter}
                        onCityChange={filter}
                    />
                </div>

                <div className={styles.refreshBar}>
                    {explorersCountResponse && (
                        <h3>{explorersCountResponse.explorersCount} Explorers</h3>
                    )}
                </div>
                <LoadingContainer loading={experiencesLoading}>
                {reloaded && (
                    <ExplorersList
                        filters={filters}
                        onSelect={onSelect}
                    />
                )}
                </LoadingContainer>

                {selectCollection && (
                    <SelectCityCollectionModal
                        onAdd={add}
                        onClose={() => toggleSelectCollection(false)}
                    />
                )}
        </Page>
    )
}

export default Explorers