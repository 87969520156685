import React from "react";
import styles from './index.module.css'
import ImageUploader from './PedImageUploader'

const MediaItem = ( {resize, name, url, setName, setUrl, action, onAction, disabled = false}) => {
    return (
         <tr>
            <td className={styles.descCol}>
                <div className={styles.description}>
                    <h3>{name}</h3>
                </div>
            </td>
            <td className={styles.imgCol}>
                <div className={styles.image}>
                    <ImageUploader 
                        resize={resize}
                        value={url} 
                        onValueChange={path => setUrl(path)}
                    />
                </div>
            </td>
            <td className={styles.actionCol}>
            </td>
         </tr>
    )
}

export default MediaItem